import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {getClientsPersonalGoals, getManagers} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import BadgeCell from "Components/Common/BadgeCell";
import { convertUTC2Local } from "utils";
import {userManager} from "../../../utils/UserManager";
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}

interface Goal {
    id: number;
    title: string;
    programId: number | null;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    lastModifiedBy: string;
}

interface GoalStep {
    id: number;
    goalId: number;
    title: string;
    programId: number | null;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    lastModifiedBy: string;
}

interface ClientGoal {
    id: number;
    clientId: number;
    goalId: number | null;
    goalStepId: number | null;
    title: string | null;
    completedAt: string | null;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    lastModifiedBy: string;
    client: any;
    goal?: Goal;
    goalStep?: GoalStep;
}
function normalizeClientGoals(clientGoals: ClientGoal[]): ClientGoal[] {
    return clientGoals.map(goal => {
        if (!goal.goal) {
            // Create default goal object
            goal.goal = {
                id: goal.goalId || -1, // Use -1 or fetch the correct ID
                title: 'Personal Goal',
                programId: null,
                createdAt: goal.createdAt,
                updatedAt: goal.updatedAt,
                deletedAt: goal.deletedAt,
                lastModifiedBy: goal.lastModifiedBy
            };
        }
        if (!goal.goalStep) {
            // Create default goal step object
            goal.goalStep = {
                id: goal.goalStepId || -1, // Use -1 or fetch the correct ID
                goalId: goal.goalId || -1,
                title: goal.title || 'Persoanl Goal Step',
                programId: null,
                createdAt: goal.createdAt,
                updatedAt: goal.updatedAt,
                deletedAt: goal.deletedAt,
                lastModifiedBy: goal.lastModifiedBy
            };
        }
        return goal;
    });
}
const ReportClientGoalsPage = ({title}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [personalGoalsData, setPersonalGoalsData] =useState<ClientGoal[]>([]);
    const [goalStateToBeShown, setGoalStateToBeShown] = useState<'active' | 'completed'>('active');
    const currentUser = userManager;
    const tenantSetting = userManager.getUser()?.tenantDetails?.settings;
    const clientTitle = (!currentUser.isCorporate() && tenantSetting?.['client_title']) ? tenantSetting?.['client_title'] : 'Clients';
    useEffect(() => {
        const fetchPersonalGoals = async () => {
            try {
                const response = await getClientsPersonalGoals(goalStateToBeShown); // Add parameters if needed
                if (response.success) {
                    const normalizedGoals = normalizeClientGoals(response.data.clientGoals);
                    setPersonalGoalsData(normalizedGoals);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch personal goals.");
                }
            } catch (error) {

                toast.error("Failed to fetch personal goals.");
            }
        };

        fetchPersonalGoals();
    }, [goalStateToBeShown]);

    const headerName = `${clientTitle} Name`;
    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: headerName,
                accessorKey: 'client.name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Phone Number',
                accessorKey: 'client.phoneNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'client.email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Birth Date',
                accessorKey: 'client.dateOfBirth',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'State',
                accessorKey: 'state',
                cell: () => <BadgeCell value={goalStateToBeShown === 'active' ? 'Active' : 'Completed'} />,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Goal',
                accessorKey: 'goal',
                cell: (info) => info.getValue()?.title ?? '',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Goal Step',
                accessorKey: 'goalStep',
                cell: (info) => info.getValue()?.title ?? '',
                enableColumnFilter: false,
                enableSorting: true,
            }
        ];

        // Only add the 'Completed At' column when the state to be shown is 'completed'
        if (goalStateToBeShown === 'completed') {
            baseColumns.push({
                header: 'Completed At',
                accessorKey: 'completedAt',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            });
        }

        return baseColumns;
    }, [goalStateToBeShown]);

    const statusRadioGroups = useMemo(() => {
        return (
            <Row className="justify-content-end">
                <Col xs="auto" className="btn-group" role="state">
                    <input type="radio" className="btn-check" name="state" id="active" autoComplete="off" defaultChecked onClick={() => setGoalStateToBeShown('active')} />
                    <label className="btn btn-outline-secondary" htmlFor="active">Active</label>

                    <input type="radio" className="btn-check" name="state" id="completed" autoComplete="off" onClick={() => setGoalStateToBeShown('completed')} />
                    <label className="btn btn-outline-secondary" htmlFor="completed">Completed</label>
                </Col>
            </Row>
        )
    }, []);

    //meta title
    document.title = `${title} | Eckerd Connects - Personal Goals`;

    return (
        <div className="page-content">
             <ToastContainer />
             <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={personalGoalsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    SearchPlaceholder="Search personal goals..."
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customRightTools={
                                                        statusRadioGroups
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ReportClientGoalsPage;
