import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {deleteClient, getClients, getManagers, getTenants, reassignClientToGroup, sendNewInvitation, updateClient } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import {UserRoleKeys, userManager} from "../../../utils/UserManager";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import InviteModal from "Components/Modal/InviteModal";
import { convertUTC2Local } from "utils";
import DeleteModal from "Components/Modal/DeleteModal";
import { Link } from "react-router-dom";
import ClientModal from "Components/Modal/ClientModal";
import ReassignManagerModal from "Components/Modal/ReassignManagerModal";
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const ClientsPage = ({title}) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [managersData, setManagersData] = useState<any[]>([]);
    const [managerFilterId, setManagerFilterId] = useState<number>();
    const [clientsData, setClientsData] = useState<any[]>([]);
    const currentUser = userManager;
    const userRole = userManager.getUserRole();
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [reassignModalIsOpen, setReassignModalIsOpen] = useState(false);

    const [newModalIsOpen, setNewModalIsOpen] = useState(false);

    const tenantSetting = userManager.getUser()?.tenantDetails?.settings;
    const currentUserRole = userManager.getUserRole();

    const clientTitle = (!currentUser.isCorporate() && tenantSetting?.['client_title']) ? tenantSetting?.['client_title'] : 'Clients';

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleReassignModal() {
        setReassignModalIsOpen(!reassignModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(state => !state);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(state => !state);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleReassignManager = (newManagerId: number) => {
        const newManager = managersData.find(item => item.id === newManagerId);
        const newGroupId = newManager['groupIds'][0];

        reassignClientToGroup(newGroupId, [valueToBeModified.id])
            .then(() => {
                setClientsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                toast.success(`The ${valueToBeModified.name} was assigned to ${newManager.name}`)
            });
    }

    const handleCreateInvite = (newInvite: any, managerId: number) => {
        const createInvite = async () => {
            try {
                if (userRole === UserRoleKeys.RoleAdmin) {
                    const manager = managersData.find(manager => manager.id === managerId);
                    newInvite['groupId'] = manager.groupIds?.[0]
                }

                const response = await sendNewInvitation(UserRoleKeys.RoleNone, newInvite); // Add parameters if needed
                if (response.success) {
                    toast.success("New invitation sent.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to send new invite.");
                }
            } catch (error: any) {

                // Check if the error message matches the condition for an existing pending invitation
                if (error.message === "failed to create auth user") {
                    toast.error("There is an existing invitation pending for this email.");
                } else {
                    toast.error("Failed to send new invite.");
                }
            }
        };

        createInvite();
    }

    const handleEditClient = (client: any) => {
        const modifyClient = async () => {
            try {
                const payload = {
                    id: client.id,
                    name: client.name,
                    phoneNumber: client.phoneNumber,
                    dateOfBirth: client.dateOfBirth.split(" ")?.[0]
                }
                const response = await updateClient(payload); // Add parameters if needed
                if (response.success) {

                    setClientsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === client.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = response.data
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update client.");
                }
            } catch (error) {

                toast.error("Failed to update client.");
            }
        };

        modifyClient();
    }

    const handleDeleteClient = () => {
        const removeClient = async () => {
            try {
                const response = await deleteClient(valueToBeModified.id); // Add parameters if needed
                if (response.success) {
                    toast.success('Client deleted');
                    setClientsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);

                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove client.");
                }
            } catch (error) {

                toast.error("Failed to remove client.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeClient()
        }
    }

    useEffect(() => {
        const fetchClients = async () => {
            try {

                // Ensure currentUser and dbUser are defined and have a groupIds array
                const user = currentUser.getUser();
                const firstGroupId = user && user.dbUser && Array.isArray(user.dbUser.groupIds) && user.dbUser.groupIds.length > 0 ? user.dbUser.groupIds[0] : null;

                const filteredGroupId = managersData.find(item => item.id === managerFilterId)?.groupIds?.[0];

                const response = await getClients(1, 0, 20, currentUser.isManager() ? firstGroupId : filteredGroupId); // Add parameters if needed
                if (response.success) {

                    setClientsData(response.data.clients);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch clients.");
                }
            } catch (error) {
                toast.error("Failed to fetch clients.");
            }
        };
        fetchClients();
    }, [userRole, managerFilterId]);

    useEffect(() => {
        if (userRole === UserRoleKeys.RoleAdmin) {
            const fetchManagers = async () => {
                try {
                    const response = await getManagers(1); // Add parameters if needed
                    if (response.success) {
                        setManagersData(response.data.managers);
                        setManagerFilterId(response.data.managers?.[0]?.id);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch managers.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch managers.");
                }
            };
            fetchManagers();
        }
    }, [userRole]);

    const customLeftTools = useMemo(() => {
        if (userRole === UserRoleKeys.RoleAdmin) {
            return (
                <React.Fragment>
                    <select className="form-select form-control" value={managerFilterId ?? 'Select'} onChange={(e) => {
                        setManagerFilterId(Number(e.target.value))
                    }}>
                    <option>Select</option>
                    {managersData?.map(manager => (
                        <option key={manager.id} value={manager.id}>{manager.name}</option>
                    ))}
                    </select>
                </React.Fragment>
            )
        } else {
            return <React.Fragment />
        }
    }, [managerFilterId, managersData, userRole]);

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Role',
                accessorKey: 'role',
                cell: (info) => <RoleCell value={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Phone Number',
                accessorKey: 'phoneNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Date of Birth',
                accessorKey: 'dateOfBirth',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            {userRole !== UserRoleKeys.RoleManager &&
                                <Link to="#" className="text-success" onClick={() => {
                                    setValueToBeModified(cellProps.row.original)
                                    toggleReassignModal()
                                }}>
                                    <i className="mdi mdi-redo font-size-18" id="reassignTooltip" />
                                    <UncontrolledTooltip placement="top" target="reassignTooltip">
                                        Reassign Manager
                                    </UncontrolledTooltip>
                                </Link>
                            }
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            }
            // Add more columns as needed
        ],
        [userRole]
    );



    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                        {isLoading ? <Spinners setLoading={setLoading} /> :
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={clientsData || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={
                                                    currentUserRole === UserRoleKeys.RoleAdmin ||
                                                    currentUserRole === UserRoleKeys.RoleCorp
                                                        ? true
                                                        : tenantSetting?.['case_manager_invite'] ?? false // Default to false if setting is undefined
                                                }
                                                isCustomPageSize={true}
                                                SearchPlaceholder={`Search ${clientTitle.toLowerCase()}...`}
                                                handleUserClick={toggleNewModal}
                                                pagination="pagination"
                                                buttonClass="btn btn-success btn-rounded"
                                                buttonName={`Invite New ${clientTitle}`}
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                theadClass="table-light"
                                                customLeftTools={
                                                    customLeftTools
                                                }
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <InviteModal showManagersDropdown={userRole === UserRoleKeys.RoleAdmin} managers={managersData} isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateInvite} />
                        <ClientModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditClient} />
                        <ReassignManagerModal selectedManagerId={managerFilterId} isOpen={reassignModalIsOpen} managers={managersData} toggle={toggleReassignModal} onSave={handleReassignManager} />
                        <DeleteModal
                            isOpen={deleteModalIsOpen}
                            toggle={toggleDeleteModal}
                            message={<p>Are you sure you want to delete? This action cannot be undone.</p>}
                            onConfirmDelete={handleDeleteClient}
                        />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ClientsPage;
