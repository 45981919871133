import React from 'react';
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Layout from "../../Layouts";
import profile from "../../assets/images/profile-img.png";
import appleStoreIcon from '../../assets/images/mobile/apple-app-store.svg'; // Adjust path as needed
import googlePlayIcon from '../../assets/images/mobile/google-play-badge.png';
const DeeplinkPage: React.FC = () => {
    // Function to read the 't' query parameter from the current URL
    const getTokenFromURL = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('t'); // Returns the value of 't' param, or null if not found
    };

    // Detect device type
    const userAgent = navigator.userAgent || navigator.vendor;
    const isMobile = /iPad|iPhone|iPod|android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    const isAndroid = /android/i.test(userAgent);

    const handleButtonClick = () => {
        const token = getTokenFromURL(); // Retrieve the token from the URL
        // Define the base URL for the mobile app custom URL scheme, dynamically inserting the token
        const customUrlScheme = token ? `connectsapp://enrollmentScreen?t=${token}` : 'connectsapp://enrollmentScreen';

        if (isMobile) {
            // Attempt to open the app on mobile devices, including the token if available
            window.location.href = customUrlScheme;
        } else {
            // Redirect to https://eckerd.org on desktop
            window.location.href = 'https://eckerd.org';
        }
    };

    return (
        <React.Fragment>
            <Layout>
        <div className="account-pages my-5 pt-sm-5">
            <Container fluid>
            <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                    <Card className="overflow-hidden">
                        <div className="bg-secondary-subtle">
                            <Row>
                                <Col className="col-7">
                                    <div className="text-primary p-4">
                                        <h5 className="text-primary">Welcome to Eckerd Connects - Engage!</h5>

                                    </div>
                                </Col>
                                <Col className="col-5 align-self-end">
                                    <img src={profile} alt="" className="img-fluid"/>
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="pt-0">
                            <br/>
                            <h2>Get Started Now:</h2>
                            <ul>
                                <li>Install the Eckerd Connects Engage APP In the Apple Store or Google Play</li>
                                <li>As a “First Time User” that has been invited, you will need to click “Enroll” from
                                    the Log-in
                                    Screen
                                </li>
                            </ul>
                            <p>Personalize your APP by viewing and completing the Getting Started Videos on the Profile
                                Page!
                                They're only a minute each, fun & easy to do, and you'll receive a $$Reward when they
                                are
                                complete!</p>
                            {isIOS && (
                                <a href="https://apps.apple.com/us/app/eckerd-connects-engage/id6444013291">
                                    <img src={appleStoreIcon} alt="Download on the Apple App Store" style={{ height: '40px' }} />
                                </a>
                            )}
                            {isAndroid && (
                                <a href="https://play.google.com/store/apps/details?id=com.eckerd.connectsapp&pli=1">
                                    <img src={googlePlayIcon} alt="Get it on Google Play" style={{ height: '60px' }} />
                                </a>
                            )}
                            <br/>
                            <div className="mt-3 d-grid">
                                <button  className="btn btn-primary btn-block " onClick={handleButtonClick}>
                                    {isMobile ? 'Continue to Registration' : 'Visit Eckerd.org Website'}
                                </button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
            </Layout>
        </React.Fragment>
    );
};

export default DeeplinkPage;
