import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FileUploader from 'Components/Common/FileUploader';
import { generalizeValue } from 'utils';

type IResourceModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  onUploadFile?: Function;
  toggle: () => void
}

const ResourceModal: React.FC<IResourceModal> = ({ data, isOpen, isEdit, onSave, onUploadFile, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  const [resourceFiles, setResourceFiles] = useState<File[]>([]);
  const [isMediaUriManuallySet, setIsMediaUriManuallySet] = useState(false);

  const handleSave = () => {
    onSave && onSave(newData, resourceFiles)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)
    // // Set flag to true if mediaUri is manually entered
      if (e.target.id === 'mediaUri' && e.target.type === 'url') {
          setIsMediaUriManuallySet(true);
          setResourceFiles([]); // Clear files if manually entering a URL
      }
    //
    //   console.log(e.target.id, e.target.type);
    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const handleUploadResouceFiles = (files: File[]) => {
      // console.log(files, isMediaUriManuallySet);
      if (!files || files.length === 0) return;

      // Prevent upload if mediaUri was manually set
      if (isMediaUriManuallySet) return;

    if (onUploadFile) {
      onUploadFile(files)
        .then((uploads: any[]) => {
          if (uploads?.[0]?.putUrl) {
            setNewData((state) => ({
              ...state,
              mediaUri: uploads?.[0]?.putUrl
            }))
          }
            setIsMediaUriManuallySet(false); // Reset flag after successful upload
        })
    }
  }

  const handleResourceFileChange = (files: File[]) => {
      // Clear the mediaUri when a new file is selected
      setNewData((state) => ({
          ...state,
          mediaUri: null // or use an empty string '' if that's preferred
      }));

      setResourceFiles(files)
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])

  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Resource" : "New Resource" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  <div className="mb-3">
                      <label htmlFor="title" className="form-label">Title</label>
                      <input value={newData?.title} type="text" className="form-control" id="title" placeholder="Enter title" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="description" className="form-label">Description</label>
                      <input value={newData?.description} type="text" className="form-control" id="description" placeholder="Enter description" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="mediaUri" className="form-label">Media URI</label>
                      <input value={newData?.mediaUri} type="url" className="form-control" id="mediaUri" placeholder="Enter media url or upload resource file" onChange={handleFieldInput} />
                  </div>
                  <FileUploader label="Resource Files" hint="Please upload files." multiple={false} onSend={handleUploadResouceFiles} onChange={handleResourceFileChange} />
                  <p>Please fill out the form to create new resource.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update resource" : "Create resource"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default ResourceModal;
