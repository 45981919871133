import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addCampaign, deleteCampaign, getCampaigns, getRuleGroups, updateCampaign } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { convertUTC2Local } from "utils";
import './styles.css';
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const StandingsPage = ({title}) => {
    const [campaignsData, setCampaignsData] = useState<any>([]);
    const [ruleGroupsData, setRuleGroupsData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await getCampaigns(1); // Add parameters if needed
                if (response.success) {

                    setCampaignsData(response.data.campaigns);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch campaigns.");
                }
            } catch (error) {

                toast.error("Failed to fetch campaigns.");
            }
        };
        const fetchRuleGroups = async () => {
            try {
                const response = await getRuleGroups(1); // Add parameters if needed
                if (response.success) {

                    setRuleGroupsData(response.data.ruleGroups);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to rule groups.");
                }
            } catch (error) {

                toast.error("Failed to fetch rule groups.");
            }
        };
        // fetchManagers();
        fetchCampaigns();
        fetchRuleGroups();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Rule Group',
                accessorKey: 'ruleGroupId',
                cell: (info) => ruleGroupsData.find(item => item.id === info.getValue())?.name || 'No group',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Description',
                accessorKey: 'description',
                enableColumnFilter: false,
                cell: (info) => <div className="wrap-text">{info.getValue()}</div>,
                enableSorting: true,
            },
            {
                header: 'Start At',
                accessorKey: 'startDate',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'End At',
                accessorKey: 'endDate',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const campaignId = cellProps.row.original.id
                    return (
                        <div className="d-flex gap-3">
                            <Link to={`/standings/${campaignId}`} className="text-primary">
                            <i className="mdi mdi-eye font-size-18" id="viewTooltip" />
                            <UncontrolledTooltip placement="top" target="viewTooltip">
                                View
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [ruleGroupsData]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={campaignsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    SearchPlaceholder="Search campaigns..."
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default StandingsPage;
