import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addGoal, addProgramGoal, deleteGoal, deleteProgramGoal, getGoals, getProgramGoals, getPrograms, updateGoal, updateProgramGoal} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import GoalModal from "Components/Modal/GoalModal";
import { convertUTC2Local } from "utils";
import { UserRoleKeys, userManager } from "utils/UserManager";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const GoalsPage = ({title}) => {
    const navigate = useNavigate();
    const [programFilterId, setProgramFilterId] = useState<number>(-1);
    const userRole = userManager.getUserRole();
    const [goalsData, setGoalsData] = useState<any>([]);
    const [programsData, setProgramsData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateGoal = (newGoal: any) => {
        const createGoal = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp ? await addProgramGoal(programFilterId, newGoal) : await addGoal(newGoal); // Add parameters if needed
                if (response.success) {

                    setGoalsData((state) => [...state, response.data]);
                    toast.success("Created new goal.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create goal.");
                }
            } catch (error) {

                toast.error("Failed to create goal.");
            }
        };

        createGoal();
    }

    const handleEditGoal = (goal: any) => {
        const modifyGoal = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp ? await updateProgramGoal(programFilterId, goal) : await updateGoal(goal); // Add parameters if needed
                if (response.success) {

                    setGoalsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === goal.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = goal
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update goal.");
                }
            } catch (error) {

                toast.error("Failed to update goal.");
            }
        };

        modifyGoal();
    }

    const handleDeleteGoal = () => {
        const removeGoal = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp ? await deleteProgramGoal(programFilterId, valueToBeModified.id) : await deleteGoal(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setGoalsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove goal.");
                }
            } catch (error) {

                toast.error("Failed to remove goal.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeGoal()
        }
    }

    useEffect(() => {
        const fetchPrograms = async () => {
            try {
                const response = await getPrograms(1); // Add parameters if needed
                if (response.success) {

                    setProgramsData(response.data.programs);
                    if (response.data && response.data.programs && response.data.programs.length > 0) {
                        // Safely set the program filter ID
                        // setProgramFilterId(response.data.programs[0].id);
                    }
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch programs.");
                }
            } catch (error) {

                toast.error("Failed to fetch programs.");
            }
        };

        if (userRole === UserRoleKeys.RoleCorp ) {
            fetchPrograms();
        }
    }, [userRole]);

    useEffect(() => {
        const fetchGoals = async () => {
            if (userRole === UserRoleKeys.RoleCorp && programFilterId !== -1 && !isNaN(Number(programFilterId))) {
                try {
                    const response = await getProgramGoals(programFilterId, 1); // Add parameters if needed
                    if (response.success) {

                        setGoalsData(response.data.goals);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch goals.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch goals.");
                }
            } else if (userRole === UserRoleKeys.RoleCorp) {
                setGoalsData([]);
            }

            if (userRole === UserRoleKeys.RoleAdmin) {
                try {
                    const response = await getGoals(1); // Add parameters if needed
                    if (response.success) {

                        setGoalsData(response.data.goals);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch goals.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch goals.");
                }
            }
        };

        fetchGoals();

    }, [programFilterId, userRole]);

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Title',
                accessorKey: 'title',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const goalId = cellProps.row.original.id
                    const viewUrl = userRole === UserRoleKeys.RoleCorp ? `/goals/${goalId}?programId=${programFilterId}` : `/goals/${goalId}`
                    return (
                        <div className="d-flex gap-3">
                            <button className="btn text-success p-0" onClick={() => {
                                setValueToBeModified(cellProps.row.original);
                                toggleEditModal();
                            }}>
                                <i className="mdi mdi-pencil font-size-18" id="editTooltip"/>
                                <UncontrolledTooltip placement="top" target="editTooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </button>
                            <Link to={viewUrl} className="text-primary">
                                <i className="mdi mdi-eye font-size-18" id="viewTooltip"/>
                                <UncontrolledTooltip placement="top" target="viewTooltip">
                                    View
                                </UncontrolledTooltip>
                            </Link>
                            <button className="btn text-danger p-0" onClick={() => {
                                setValueToBeModified(cellProps.row.original);
                                toggleDeleteModal();
                            }}>
                                <i className="mdi mdi-delete font-size-18" id="deleteTooltip"/>
                                <UncontrolledTooltip placement="top" target="deleteTooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </button>

                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal, programFilterId, userRole]
    );

    const customLeftTools = useMemo(() => {
        if (userRole === UserRoleKeys.RoleCorp) {
            return (
                <React.Fragment>
                    <select className="form-select form-control" value={programFilterId ?? ''} onChange={(e) => {
                        setProgramFilterId(Number(e.target.value))
                    }}>
                        <option value={-1}>Select a location</option>
                        {programsData?.map(program => (
                        <option key={program.id} value={program.id}>{program.name}</option>
                    ))}
                    </select>
                </React.Fragment>
            )
        } else {
            return <React.Fragment />
        }
    }, [programsData, programFilterId, userRole]);

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={goalsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={programFilterId !== -1 || userRole !== UserRoleKeys.RoleCorp}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search goals..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Goal"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customLeftTools={
                                                        customLeftTools
                                                    }
                                                    isNoData
                                                    noDataText="No data available, please select another location."
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <GoalModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateGoal} />
                            <GoalModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditGoal} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this goal? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteGoal}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default GoalsPage;
