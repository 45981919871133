import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generalizeValue } from 'utils';

type IGettingStartedVideoModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  toggle: () => void
}

const GettingStartedVideoModal: React.FC<IGettingStartedVideoModal> = ({ data, isOpen, isEdit, onSave, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  
  const handleSave = () => {
    onSave && onSave(newData)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else  {
        setNewData({})
    }
  }, [data, isEdit, isOpen])
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Getting Started Video" : "New Getting Started Video" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  <div className="mb-3">
                      <label htmlFor="title" className="form-label">Title</label>
                      <input value={newData?.title} type="text" className="form-control" id="title" placeholder="Enter title" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="vimeoId" className="form-label">Vimeo ID</label>
                      <input value={newData?.vimeoId} type="text" className="form-control" id="vimeoId" placeholder="Enter video id" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="subtitle" className="form-label">Subtitle</label>
                      <input value={newData?.subtitle} type="text" className="form-control" id="subtitle" placeholder="Enter subtitle" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="order" className="form-label">Order</label>
                      <input value={newData?.order} type="number" className="form-control" id="order" placeholder="Enter order" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="type" className="form-label">Type</label>
                      <input value={newData?.type} type="type" className="form-control" id="type" placeholder="Enter type" onChange={handleFieldInput} />
                  </div>
                  <p>Please fill out the form to create new getting started video.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update" : "Create"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default GettingStartedVideoModal;
