import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap';
import { generalizeValue } from 'utils';

type IRuleGroupAssignModal = {
  attachedGroups?: any[];
  ruleGroups?: any[];
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  onAttach?: Function;
  onDetach?: Function;
  toggle: () => void
}

const RuleGroupAssignModal: React.FC<IRuleGroupAssignModal> = ({ attachedGroups = [], ruleGroups = [], isOpen, isEdit, onSave, onAttach, onDetach, toggle }) => {

  const handleAttachGroup = (id: number) => {
    onAttach && onAttach(id);
  }

  const handleDetachGroup = (id: number) => {
    onDetach && onDetach(id);
  }

  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                Groups
              </div>
          </ModalHeader>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
              <form>
                  <p>Please attach/detach this rule to any group.</p>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Attached Groups</label>
                    <div className="p-2">
                      {attachedGroups?.map(group => (
                        <Row className="mb-3" key={group.id} style={{ alignItems: 'center' }}>
                          <Col>
                            {group?.name ?? ''}
                          </Col>
                          <Col className="col-auto text-end">
                            <Button type="button" outline color="danger" className="btn-sm" onClick={() => handleDetachGroup(group.id)}>
                              Detach
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      {!attachedGroups?.length && (
                        "No groups attached"
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Please select a group to attach this rule.</label>
                    <div className="p-2">
                      {ruleGroups?.filter(unattachedGroup => !attachedGroups.find(attachedGroup => attachedGroup.id === unattachedGroup.id))?.map(group => (
                        <Row className="mb-3" key={group.id} style={{ alignItems: 'center' }}>
                          <Col>
                            {group?.name ?? ''}
                          </Col>
                          <Col className="col-auto text-end">
                            <Button type="button" outline color="primary" className="btn-sm" onClick={() => handleAttachGroup(group.id)}>
                              Attach
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      {!ruleGroups?.filter(unattachedGroup => !attachedGroups.find(attachedGroup => attachedGroup.id === unattachedGroup.id))?.length && (
                        "There's no groups to attach this rule."
                      )}
                    </div>
                  </div>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default RuleGroupAssignModal;
