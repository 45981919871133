import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generalizeValue } from 'utils';

type IReportPreviewModal = {
  data?: any[];
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  toggle: () => void
}

const ReportPreviewModal: React.FC<IReportPreviewModal> = ({ data = [], isOpen, toggle }) => {
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="reportModalLabel">
                  Report
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  {data.length === 0 && (
                    <p>No reports available</p>
                  )}
                  {data.map((item, index) => (
                    <div key={index} className="mb-3 d-flex justify-content-center">
                      <img src={item} alt="" className="" width="200" height="200" />   
                    </div>
                  ))}
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default ReportPreviewModal;
