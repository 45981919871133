import React, { useEffect, useState } from "react";
import "./App.css";
import {
  authAdminProtectedRoutes,
  authManagersProtectedRoutes,
  authProtectedRoutes,
  publicRoutes,
} from "./Routes/allRoutes";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import VerticalLayout from "./Layouts/VerticalLayout";
import HorizontalLayout from "./Layouts/HorizontalLayout/index";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./Layouts/NonLayout";
import config from './env.config';
//constants
import {
  LAYOUT_TYPES,
} from "./Components/constants/layout";

import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import AuthProtected from "./Routes/AuthProtected";
import { initCognitoBackend } from "./helpers/cognito_helper";
import { userManager, UserRoleKeys } from "./utils/UserManager";

function App() {

  const [currentRoutes, setCurrentRoutes] = useState([{ path: "/", component: "" }]);
  const selectLeadData = createSelector(
      (state: any) => state.Layout,
      (layout) => ({
        layoutTypes: layout.layoutTypes
      })
  );
  const { layoutTypes } = useSelector(selectLeadData);
  const Layout = getLayout(layoutTypes);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const cognitoConfig = {
      UserPoolId: config.COGNITO_POOL_ID,
      ClientId: config.COGNITO_CLIENT_ID,
    };

    initCognitoBackend(cognitoConfig);

    const handleAuthChange = () => {
      // Logic to update routes based on new auth status or role
      const currentUserRole = userManager.getUserRole(); // Get current user's role

      // Paths that can bypass the role check
      const allowedPaths = ['/mobile', '/register','/forgot-password'];
      const currentPath = location.pathname;
      const isAllowedPath = allowedPaths.some(path => currentPath.startsWith(path));

      if (!currentUserRole && !isAllowedPath) {
        // If no role and not accessing an allowed path, redirect to login
        navigate('/login');
        return;
      }

      let routes;
      switch (currentUserRole) {
        case UserRoleKeys.RoleAdmin:
          routes = authAdminProtectedRoutes;
          break;
        case UserRoleKeys.RoleManager:
          routes = authManagersProtectedRoutes;
          break;
        default:
          routes = authProtectedRoutes; // Default role routes
      }

      setCurrentRoutes(routes);
    };

    // Subscribe to auth changes
    userManager.onAuthChange(handleAuthChange);

    // Initial check
    handleAuthChange();

    // Cleanup subscription on unmount
    return () => {
      userManager.offAuthChange(handleAuthChange);
    };
  }, [location.pathname]); // Re-run the effect when the pathname changes

  return (
      <React.Fragment>
        <Routes>
          {publicRoutes.map((route, idx) => (
              <Route path={route.path} key={idx}
                     element={<NonAuthLayout>{route.component}</NonAuthLayout>} />
          ))}
          {currentRoutes.map((route, idx) => (
              <Route
                  path={route.path}
                  key={idx}
                  element={
                    <React.Fragment>
                      <AuthProtected>
                        <Layout>
                          {route.component}
                        </Layout>
                      </AuthProtected>
                    </React.Fragment>
                  }
              />
          ))}
        </Routes>
      </React.Fragment>
  );
}

export default App;

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case LAYOUT_TYPES.VERTICAL:
      Layout = VerticalLayout;
      break;
    case LAYOUT_TYPES.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};
