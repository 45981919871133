import React from "react";
import TableContainer, { TableContainerProps } from "./TableContainer";
import { Button, Row, Col } from "reactstrap";

interface ExtendedTableContainerProps extends TableContainerProps {
    isAddButton?: boolean;
    buttonClass?: string;
    buttonName?: string;
    handleUserClick?: (event: any) => void;
    currentPage?: number; // Current page index
    pageSize?: number; // Number of items per page
    totalResults?: number; // Total number of results
    onPageChange?: (pageIndex: number) => void; // Callback for changing pages
    onPageSizeChange?: (pageSize: number) => void; // Callback for changing page size
}

const ExtendedTableContainer = ({
                                    isAddButton,
                                    buttonClass,
                                    buttonName,
                                    handleUserClick,
                                    currentPage = 0,
                                    pageSize = 10,
                                    totalResults = 0,
                                    onPageChange,
                                    onPageSizeChange,
                                    isPagination,
                                    ...props
                                }: ExtendedTableContainerProps) => {
    const totalPages = Math.ceil(totalResults / pageSize);

    return (
        <div>
            {isAddButton && (
                <div className="text-sm-end mt-2 mb-2">
                    <Button type="button" className={buttonClass} onClick={handleUserClick}>
                        <i className="mdi mdi-plus me-1"></i> {buttonName || "Add"}
                    </Button>
                </div>
            )}

            <TableContainer {...props} isPagination={false}  isCustomPageSize={false}     pageSize={pageSize}   paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                            theadClass="table-light"/>

            {isPagination && (
                <Row className="mt-3">
                    <Col>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                Showing {(currentPage * pageSize) + 1} to{" "}
                                {Math.min((currentPage + 1) * pageSize, totalResults)} of{" "}
                                {totalResults} results
                            </div>
                            <div className="d-flex align-items-center">
                                <select
                                    className="form-select me-3"
                                    value={pageSize}
                                    onChange={(e) => onPageSizeChange && onPageSizeChange(Number(e.target.value))}
                                    style={{ width: "100px" }}
                                >
                                    {[10, 20, 30, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                                <Button
                                    disabled={currentPage === 0}
                                    onClick={() => onPageChange && onPageChange(currentPage - 1)}
                                >
                                    Previous
                                </Button>
                                <Button
                                    disabled={currentPage >= totalPages - 1}
                                    onClick={() => onPageChange && onPageChange(currentPage + 1)}
                                    className="ms-2"
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default ExtendedTableContainer;
