import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmModal = ({ isOpen, toggle, onConfirm, message }) => {
    // userName is optional; include it if you want to personalize the confirmation message.
    const handleConfirm = () => {
        onConfirm(); // This should be a function passed down that handles the actual deletion logic.
        toggle(); // Close the modal after confirming the deletion.
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="deleteModalLabel">
                    Confirm
                </div>
            </ModalHeader>
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={handleConfirm}
                    className="btn btn-primary"
                >
                    Confirm
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;
