import React from "react";

type ThumbnailProps = {
    url: string;
  };

  const ThumbnailCell: React.FC<ThumbnailProps> = ({ url, badgeColor = 'primary' }: { url: string, badgeColor?: 'primary' | 'success' | 'info' | 'danger'}) => {
    return  (
      <img src={url} alt="No thumbnail" title="thumbnail-img" className="avatar-md" />
    )
  };

export default React.memo(ThumbnailCell);