import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { generalizeValue } from 'utils';

type IRuleModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  onManageGroup?: Function;
  toggle: () => void
}

const RuleModal: React.FC<IRuleModal> = ({ data, isOpen, isEdit, onSave, onManageGroup, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  
  const handleSave = () => {
    onSave && onSave(newData)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const handleGroupManage = () => {
    onManageGroup && onManageGroup()
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Rule" : "New Rule" }
              </div>
          </ModalHeader>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
              <form className="">
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input value={newData?.name} type="text" className="form-control" id="name" placeholder="Enter name" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="event" className="form-label">Event</label>
                      <input value={newData?.event} type="text" className="form-control" id="event" placeholder="Enter event" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="points" className="form-label">Points</label>
                      <input value={newData?.points ?? ''} type="number" className="form-control" id="points" placeholder="Enter point" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="maxPoints7Days" className="form-label">Max Points (7 Days)</label>
                      <input value={newData?.maxPoints7Days ?? ''} type="number" className="form-control" id="maxPoints7Days" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="maxUsage7Days" className="form-label">Max Usage (7 Days)</label>
                      <input value={newData?.maxUsage7Days ?? ''} type="number" className="form-control" id="maxUsage7Days" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="maxPoints45Days" className="form-label">Max Points (45 Days)</label>
                      <input value={newData?.maxPoints45Days ?? ''} type="number" className="form-control" id="maxPoints45Days" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="maxUsage45Days" className="form-label">Max Usage (45 Days)</label>
                      <input value={newData?.maxUsage45Days ?? ''} type="number" className="form-control" id="maxUsage45Days" onChange={handleFieldInput} />
                  </div>
                  {isEdit && (
                    <div className="mb-3 d-grid gap-2">
                      <Button
                        color="primary"
                        type="button"
                        outline
                        onClick={handleGroupManage}
                      >
                        Manage Groups
                      </Button>
                    </div>
                  )}
                  <p>Please fill out the form to create new rule.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update rule" : "Create rule"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default RuleModal;
