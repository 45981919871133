import React, {useState} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux


import {Link, useNavigate} from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../slices/thunk";
import {toast, ToastContainer} from "react-toastify";
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { createSelector } from 'reselect';
import {getCognitoBackend} from "helpers/cognito_helper";

const ForgetPasswordPage = (props) => {

    //meta title
    document.title = "Forget Password | Eckerd Connects -  Admin";

    const navigate = useNavigate();
    const [isCodeSent, setIsCodeSent] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            code: '',
            newPassword: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter your email"),
            ...(isCodeSent && {
                code: Yup.string().required("Please enter the confirmation code"),
                newPassword: Yup.string().required("Please enter a new password"),
            }),
        }),
        onSubmit: async (values) => {
            if (!isCodeSent) {
                try {
                    await getCognitoBackend()?.forgotPassword(values.email);
                    toast.success("Successfully sent reset password instructions.");
                    setIsCodeSent(true);
                } catch (error) {
                    toast.error("Failed to send reset password instructions: " + error);
                }
            } else {
                try {
                    await getCognitoBackend()?.changePassword(values.email, values.code, values.newPassword);
                    toast.success("Password changed successfully.");
                    setIsCodeSent(false); // Optionally reset the form or redirect the user
                    navigate('/login'); // Redirect to login page
                } catch (error) {
                    toast.error("Failed to change password: " + error);
                }
            }
        }
    });



    return (
        <React.Fragment>
            <ToastContainer />
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-softbg-soft-primary">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p>Sign in to continue to Skote.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">


                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            {!isCodeSent ? (
                                                // First step: Request reset code
                                                <div className="mb-3">
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={validation.touched.email && validation.errors.email ? true : false}
                                                    />
                                                    {validation.touched.email && validation.errors.email && (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    )}
                                                </div>
                                            ) : (
                                                // Second step: Enter confirmation code and new password
                                                <>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Confirmation Code</Label>
                                                        <Input
                                                            name="code"
                                                            className="form-control"
                                                            placeholder="Enter confirmation code"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.code || ""}
                                                            invalid={validation.touched.code && validation.errors.code ? true : false}
                                                        />
                                                        {validation.touched.code && validation.errors.code && (
                                                            <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">New Password</Label>
                                                        <Input
                                                            name="newPassword"
                                                            className="form-control"
                                                            placeholder="Enter new password"
                                                            type="password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.newPassword || ""}
                                                            invalid={validation.touched.newPassword && validation.errors.newPassword ? true : false}
                                                        />
                                                        {validation.touched.newPassword && validation.errors.newPassword && (
                                                            <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            <Row className="mb-3">
                                                <Col className="text-end">
                                                    <button className="btn btn-primary w-md" type="submit">
                                                        {isCodeSent ? "Change Password" : "Reset"}
                                                    </button>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>
                                    © {new Date().getFullYear()} Eckerd Connects.

                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ForgetPasswordPage);
