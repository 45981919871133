import {getCognitoBackend} from "helpers/cognito_helper";
import {apiError, loginSuccess} from "../slices/auth/login/reducer";
import {getMe} from "helpers/backend_helper";
import {setAuthorization} from "helpers/api_helper";
import {Dispatch} from "react";
import {getErrorMessage} from "../slices/auth/login/thunk";

type UserData = {
    email: string;
    tenant: string;
    role: string;
    isCorporate?: boolean;
    tenantAssumptionId?: number;
    dbUser: {
        id: number;
        cognitoUuid: string;
        name: string;
        phoneNumber: string;
        email: string;
        role: string;
        createdAt: string;
        updatedAt: string | null;
        deletedAt: string | null;
        lastModifiedBy: string;
        groupIds: number[];
    };
    tenantDetails: {
        settings: {
            case_manager_invite?: boolean;
            case_manager_push?: boolean;
            enabledGettingStartedVideoIds?: number[];
            chatbot_enabled?: boolean;
        };
        dbName: string;
        name: string;
        id: number;
    }
};

type ApiResponse<T = any> = {
    data: T;
    error: string | null;
    success: boolean;
};
enum UserRole {
    RoleCorp = "Corporate",
    RoleAdmin = "Admin",
    RoleManager = "Manager",
    // Add other roles as needed
    RoleNone = "Client"
}
export enum UserRoleKeys {
    RoleCorp = "role_corp",
    RoleAdmin = "role_admin",
    RoleManager = "role_manager",
    // Add other roles as needed
    RoleNone = "role_client"
}
export function mapRoleToEnum(role: string | undefined): UserRole | null {

    switch (role) {
        case UserRoleKeys.RoleCorp:
            return UserRole.RoleCorp;
        case UserRoleKeys.RoleAdmin:
            return UserRole.RoleAdmin;
        case UserRoleKeys.RoleManager:
            return UserRole.RoleManager;
        default:
            return UserRole.RoleNone; // or UserRole.Default if you have a default role
    }
}
function getReadableRole(role: string | undefined): UserRole | null {
    return mapRoleToEnum(role);
}
type AuthChangeCallback = () => void;
class UserManager {
    private static instance: UserManager;
    private userData: UserData | null = null;
    private authChangeSubscribers: AuthChangeCallback[] = [];

    private constructor() {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            this.userData = JSON.parse(storedUserData);
        }
        const storeJwtToken = localStorage.getItem('authUser');
        if (storedUserData) {
            setAuthorization(storeJwtToken);
        }
        this.authChangeSubscribers = [];

    }
    // Method to call when the auth status changes
    public onAuthChange(callback: AuthChangeCallback): void {
        this.authChangeSubscribers.push(callback);
    }

    public offAuthChange(callback: AuthChangeCallback): void {
        this.authChangeSubscribers = this.authChangeSubscribers.filter(cb => cb !== callback);
    }

    public broadcastAuthChange(): void {
        this.authChangeSubscribers.forEach(callback => callback());
    }

    static getInstance(): UserManager {
        if (!UserManager.instance) {
            UserManager.instance = new UserManager();
        }
        return UserManager.instance;
    }

    async loginUser(email: string, password: string, dispatch: Dispatch<any>, history: any): Promise<void> {
        try {
            const cognitoBackend = getCognitoBackend();
            if (!cognitoBackend) {
                throw new Error('Cognito backend not initialized');
            }

            const session = await cognitoBackend.loginUser(email, password);
            const jwtToken = session.getIdToken().getJwtToken();
            // const idToken = session.getIdToken().;
            const refreshToken = session.getRefreshToken().getToken();
            localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
            localStorage.setItem("authUser", JSON.stringify(jwtToken));
            // localStorage.setItem("idToken", JSON.stringify(idToken));

            setAuthorization(jwtToken);

            const response: ApiResponse<UserData> = await getMe();



            if (!response) {
               const error =  new Error('Response unknown');
                const errorMessage = getErrorMessage(error);
                dispatch(apiError(errorMessage));
                return ;
            }
            if (!response.success) {
                const error = new Error(response.error || 'Failed to fetch user data');
                const errorMessage = getErrorMessage(error);
                dispatch(apiError(errorMessage));
                return;
            }

            if (!response.data) {
                const error =new Error('User data is empty');
                const errorMessage = getErrorMessage(error);
                dispatch(apiError(errorMessage));
                return;
            }

            this.userData = response.data;
            localStorage.setItem('userData', JSON.stringify(this.userData));
            this.broadcastAuthChange();
            dispatch(loginSuccess(jwtToken));
            history('/');
            console.log("Login successful", response.data);
            // Additional logic using userData

        } catch (error) {
            console.error('Login failed', error);
            // Dispatch an action for failed login if necessary
            const errorMessage = getErrorMessage(error);
            dispatch(apiError(errorMessage));
        }
    }



    isUserLoggedIn(): boolean {
        // Check session token validity
        // Possibly check localStorage or cookies
        return this.userData != null;
    }

    isCorporate()
    {
        return this.userData?.role === UserRoleKeys.RoleCorp;
    }
    isAssumedCorporate()
    {
      return this.userData?.isCorporate;
    }
    isManager()
    {
        return this.userData?.role === UserRoleKeys.RoleManager;
    }
    isAdmin()
    {
        return this.userData?.role === UserRoleKeys.RoleAdmin;
    }
    getUserRole(): string | null {
        return this.userData?.role ?? null;
    }
    getUserTenant(): string | null {
        return this.userData?.tenant ?? null;
    }
    isChatbotEnabled = () => {
      return this.userData?.tenantDetails?.settings?.chatbot_enabled;
    };
    getUserTenantName(): string | null {
        return this.userData?.tenantDetails.name ?? null;
    }
    getUserRoleReadable(): UserRole | null {
        const role = this.userData?.role;
        return getReadableRole(role);
    }

    getUsername(): string | null {
        return this.userData?.dbUser.name ?? null; // Replace 'username' with the actual property name
    }
    getUser(): UserData | null {
        return this.userData ?? null; // Replace 'username' with the actual property name
    }
    setUserData(userData: UserData, options?: { quiet?: boolean }): void {
        this.userData = JSON.parse(JSON.stringify(userData))
        localStorage.setItem('userData', JSON.stringify(this.userData));
        if (!options?.quiet) {
          this.broadcastAuthChange();
        }
    }
    logoutUser(): void {
        const cognitoBackend = getCognitoBackend();
        if (cognitoBackend) {
            cognitoBackend.logout();
        }
        // Clear session token from localStorage or cookies
        this.userData = null;
        localStorage.removeItem("authUser");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userData");
        this.broadcastAuthChange();

    }
    async refreshCognitoSession() {
        const cognitoBackend = getCognitoBackend();
        if (!cognitoBackend) {
            throw new Error('Cognito backend not initialized');
        }

        const refreshToken = JSON.parse(<string>localStorage.getItem("refreshToken"));
        if (!refreshToken) {
            this.logoutUser();
            throw new Error('No refresh token available');
        }

        try {
            const session = await cognitoBackend.refreshSession(refreshToken);  // Ensure your cognito backend can handle this
            const newJwtToken = session.getIdToken().getJwtToken();

            localStorage.setItem("authUser", JSON.stringify(newJwtToken));
            setAuthorization(newJwtToken);

            console.log("Session refreshed successfully");
            return newJwtToken;
        } catch (error) {
            this.logoutUser();
            console.error("Error refreshing the session: ", error);
            throw new Error('Session refresh failed');
        }
    }
    // Additional methods...
}

export const userManager = UserManager.getInstance();


