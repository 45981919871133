import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generalizeValue } from 'utils';

type IGuardrailModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  guardrailType: string;
  toggle: () => void
}

const GuardrailModal: React.FC<IGuardrailModal> = ({ data, isOpen, isEdit, onSave, toggle, guardrailType }) => {
  const [newData, setNewData] = useState<any>({});
  const [phraseCount, setPhraseCount] = useState<number>(0);

  const strengths = [
    "NONE",
    "LOW",
    "MEDIUM",
    "HIGH"
  ];

  const handleSave = () => {
    onSave && onSave(newData);
    toggle();
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const handlePhraseInput = (idx, example) => {
    setNewData((state) => {
      const examples = state.examples ?? [];
      examples[idx] = example;
      return {
        ...state,
        examples
      };
    });
  };

  const handlePhraseRemoval = (idx) => {
    setNewData((state) => ({...state, examples: (state.examples ?? []).filter((v, i) => i !== idx)}));
    setPhraseCount(phraseCount - 1);
  };

  useEffect(() => {
    if (isEdit) {
      setNewData({...data});
      setPhraseCount(data?.examples?.length ?? 0);
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])


  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? `Update ${newData?.type ?? newData?.name} Guardrail` : "New Guardrail" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  {!isEdit && guardrailType === "topicPolicy" && <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <br></br>
                      <p className="form-label">This value cannot be edited after creation of the guardrail.</p>
                      <input value={newData?.name} type="text" className="form-control" id="name" placeholder="Enter guardrail name" onChange={handleFieldInput} />
                  </div>}
                  {guardrailType === "contentPolicy" && <div className="mb-3">
                      <label htmlFor="description" className="form-label">Description</label>
                      <br></br>
                      <p className="form-label">{newData?.description}</p>
                      <select className="form-select form-control" value={newData?.strength ?? 'Select'} onChange={handleFieldInput}>
                        <option>Select</option>
                        {strengths.map(s => (<option key={s} value={s}>{s}</option>))}
                      </select>
                  </div>}
                  {guardrailType === "topicPolicy" &&
                    <div className="mb-3">
                      <label htmlFor="definition" className="form-label">Definition</label>
                      <br></br>
                      <p className="form-label">Provide a clear definition to detect user inputs that fall into this topic. Avoid starting with “don't”.</p>
                      <input value={newData?.definition} type="text" className="form-control" id="definition" placeholder="Enter topic definition" onChange={handleFieldInput} />
                    </div>}
                  {guardrailType === "topicPolicy" &&
                    <div className="mb-3">
                      <label htmlFor="examples" className="form-label">Example Phrases</label>
                      <br></br>
                      <p className="form-label">Provide example messages that a client would send. Include up to 5.</p>
                      {Array.from(Array(phraseCount).keys()).map(idx => (
                        <>
                          <div key={idx} style={{flexDirection: "row"}}>
                            <input value={newData?.examples?.[idx] ?? ""} type="text" className="form-control" id={`phrase${idx+1}`} placeholder="Enter example phrase" onChange={(e) => handlePhraseInput(idx, e.target.value)} />
                            <button
                                type="button"
                                className="btn btn-danger"
                                color="red"
                                onClick={() => {handlePhraseRemoval(idx)}}
                            >
                                Delete
                            </button>
                          </div>
                          <br></br>
                        </>
                      ))}
                      {phraseCount < 5 && 
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {setPhraseCount(phraseCount + 1)}}
                        >
                            Add
                        </button>
                      }
                    </div>
                  }
                  <div className="mb-3">
                      <label htmlFor="response" className="form-label">Bot Response</label>
                      <br></br>
                      <p className="form-label">The chatbot's default response if this guardrail is triggered.</p>
                      <input value={newData?.response} type="text" className="form-control" id="response" placeholder="Enter default bot response" onChange={handleFieldInput} />
                  </div>
                  <label htmlFor="escalation-description" className="form-label">Escalation Paths</label>
                  <div className="form-check mb-3">
                    <label className="form-check-label" htmlFor="escalation_paths_manager">
                        Manager
                    </label>
                    <input
                      checked={newData?.escalation_paths?.includes("manager") ?? false}
                      className="form-check-input"
                      type="checkbox"
                      id="escalation_paths_manager"
                      onChange={() => {
                        setNewData((state) => {
                          let escalationPaths: string[] = [];

                          // perform the inverse operation based on the current state
                          if (state.escalation_paths?.includes("manager")) {
                            escalationPaths = state.escalation_paths.filter(p => p !== "manager");
                          } else {
                            if (state.escalation_paths) {
                              escalationPaths.push(...state.escalation_paths);
                            }
                            escalationPaths.push("manager");
                          }
                          return {
                            ...state,
                            "escalation_paths": escalationPaths
                            }
                        })
                      }}
                    />
                  </div>
                  <div className="form-check mb-3">
                    <label className="form-check-label" htmlFor="escalation_paths_admins">
                          Location Admins
                      </label>
                      <input
                        checked={newData?.escalation_paths?.includes("admins") ?? false}
                        className="form-check-input"
                        type="checkbox"
                        id="escalation_paths_admins"
                        onChange={() => {
                          setNewData((state) => {
                            let escalationPaths: string[] = [];

                            // perform the inverse operation based on the current state
                            if (state.escalation_paths?.includes("admins")) {
                              escalationPaths = state.escalation_paths.filter(p => p !== "admins");
                            } else {
                              if (state.escalation_paths) {
                                escalationPaths.push(...state.escalation_paths);
                              }
                              escalationPaths.push("admins");
                            }
                            return {
                              ...state,
                              "escalation_paths": escalationPaths
                              }
                          })
                        }}
                      />
                    </div>
                    <label htmlFor="method-description" className="form-label">Escalation Methods</label>
                  <div className="form-check mb-3">
                    <label className="form-check-label" htmlFor="escalation_methods_email">
                        Email
                    </label>
                    <input
                      checked={newData?.escalation_methods?.includes("email") ?? false}
                      className="form-check-input"
                      type="checkbox"
                      id="escalation_methods_email"
                      onChange={() => {
                        setNewData((state) => {
                          let escalationMethods: string[] = [];

                          if (state.escalation_methods?.includes("email")) {
                            escalationMethods = state.escalation_methods.filter(m => m !== "email");
                          } else {
                            if (state.escalation_methods) {
                              escalationMethods.push(...state.escalation_methods);
                            }
                            escalationMethods.push("email");
                          }
                          return {
                            ...state,
                            "escalation_methods": escalationMethods
                            }
                        })
                      }}
                    />
                  </div>
                  <div className="form-check mb-3">
                    <label className="form-check-label" htmlFor="escalation_methods_text">
                          Text
                      </label>
                      <input
                        checked={newData?.escalation_methods?.includes("text") ?? false}
                        className="form-check-input"
                        type="checkbox"
                        id="escalation_methods_text"
                        onChange={() => {
                          setNewData((state) => {
                            let escalationMethods: string[] = [];

                            if (state.escalation_methods?.includes("text")) {
                              escalationMethods = state.escalation_methods.filter(p => p !== "text");
                            } else {
                              if (state.escalation_methods) {
                                escalationMethods.push(...state.escalation_methods);
                              }
                              escalationMethods.push("text");
                            }
                            return {
                              ...state,
                              "escalation_methods": escalationMethods
                              }
                          })
                        }}
                      />
                    </div>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update Guardrail" : "Create Guardrail"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default GuardrailModal;
