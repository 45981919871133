import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {addRuleGroup, deleteRuleGroup, getRuleGroups, updateRuleGroup} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import RuleGroupModal from "Components/Modal/RuleGroupModal";
import { convertUTC2Local } from "utils";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const RuleGroupsPage = ({title}) => {
    const [managersData, setManagersData] = useState([]);
    const [ruleGroupsData, setRuleGroupsData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateRuleGroup = (newGroup: any) => {
        const createRuleGroup = async () => {
            try {
                const response = await addRuleGroup(newGroup); // Add parameters if needed
                if (response.success) {

                    setRuleGroupsData((state) => [...state, response.data]);
                    toast.success("Created new rule group.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create rule group.");
                }
            } catch (error) {

                toast.error("Failed to create rule group.");
            }
        };

        createRuleGroup();
    }

    const handleEditRuleGroup = (group: any) => {
        const modifyRuleGroup = async () => {
            try {
                const response = await updateRuleGroup(group); // Add parameters if needed
                if (response.success) {

                    setRuleGroupsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === group.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = group
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update rule group.");
                }
            } catch (error) {

                toast.error("Failed to update rule group.");
            }
        };

        modifyRuleGroup();
    }

    const handleDeleteRuleGroup = () => {
        const removeRuleGroup = async () => {
            try {
                const response = await deleteRuleGroup(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setRuleGroupsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove rule group.");
                }
            } catch (error) {

                toast.error("Failed to remove rule group.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeRuleGroup()
        }
    }

    useEffect(() => {
        const fetchRuleGroups = async () => {
            try {
                const response = await getRuleGroups(1); // Add parameters if needed
                if (response.success) {

                    setRuleGroupsData(response.data.ruleGroups);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch rule groups.");
                }
            } catch (error) {

                toast.error("Failed to fetch rule groups.");
            }
        };
        // fetchManagers();
        fetchRuleGroups();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Rules',
                accessorKey: 'rules',
                cell: (info) => (info.getValue() ?? []).map(item => item?.name).join(' | ') || 'No rule',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={ruleGroupsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search groups..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Group"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <RuleGroupModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateRuleGroup} />
                            <RuleGroupModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditRuleGroup} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this group? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteRuleGroup}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default RuleGroupsPage;
