import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";
import withRouter from "../../Common/withRouter";
import { createSelector } from 'reselect';

// users
import user1 from "../../../assets/images/users/logo192.png";

import { useSelector } from "react-redux";
import {userManager} from "../../../utils/UserManager";
import RoleCell from "Components/Common/RoleCell";
import ChangeLocationModal from "Components/Modal/ChangeLocationModal";

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"

  const [menu, setMenu] = useState(false);

  const [username, setUsername] = useState("Admin");
  const [readableRole, setReadableRole] = useState("Admin");

  const [locationModalOpen, setLocationModalOpen] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggleLocationModal = () => {
    setLocationModalOpen(!locationModalOpen);
    removeBodyCss();
  }

  const selectProfileProperties = createSelector(
    (state: any) => state.Profile,
    (profile) => ({
      user: profile.user,
    })
  );

  const { user } = useSelector(selectProfileProperties);

  useEffect(() => {
    const storedUsername = userManager.getUsername();
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  useEffect(() => {
    const storedRole = userManager.getUserRole();
    if (storedRole) {

      setReadableRole(storedRole);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser") || "");
        setUsername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setUsername(user?.username);
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
            className="btn header-item "
            id="page-header-user-dropdown"
            tag="button"
        >
          <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username || "admin"}
           <RoleCell value={readableRole}></RoleCell>
          </span>

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href={process.env.PUBLIC_URL + "/profile"}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
        
          {userManager.isAssumedCorporate() && <DropdownItem tag="a" onClick={toggleLocationModal}>
            {" "}
            <i className="bx bx-arrow-back font-size-16 align-middle me-1" />
            {props.t("Switch Location")}{" "}
          </DropdownItem>}

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <ChangeLocationModal isOpen={locationModalOpen} toggle={toggleLocationModal} />
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));
