import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generalizeValue } from 'utils';
import { UserRoleKeys } from 'utils/UserManager';

const InviteModal = ({ showManagersDropdown = false, showTenantsDropdown = false, managers = [] as any[], tenants = [] as any[], isOpen, toggle, onSave }) => {
    const [newData, setNewData] = useState<any>({});
    const [managerId, setManagerId] = useState<number>();
    const [tenantId, setTenantId] = useState<number>();

    const handleSave = () => {
        if (showManagersDropdown) {
            onSave && onSave(newData, managerId)
        } else if (showTenantsDropdown) {
            onSave && onSave(newData, tenantId)
        } else {
            onSave && onSave(newData)
        }
    }

    const handleFieldInput = (e) => {
        const generalizedValue = generalizeValue(e.target.type, e.target.value)

        setNewData((state) => ({
            ...state,
            [e.target.id]: generalizedValue
        }))
    }

    useEffect(() => {
        setManagerId(undefined);
        setTenantId(undefined);
        setNewData({})
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    Invite Someone
                </div>
            </ModalHeader>
            <ModalBody>
                <form>
                    <div className="mb-3">
                        <label htmlFor="nameInvited" className="form-label">Name</label>
                        <input value={newData?.nameInvited ?? ''} type="text" className="form-control" id="nameInvited" placeholder="Enter name" onChange={handleFieldInput} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="emailInvited" className="form-label">Email address</label>
                        <input value={newData?.emailInvited ?? ''} type="email" className="form-control" id="emailInvited" placeholder="Enter email" onChange={handleFieldInput} />
                    </div>
                    {showManagersDropdown && (
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Managers</label>
                            <select className="form-select form-control" value={managerId ?? 'Select'} onChange={(e) => {
                                setManagerId(Number(e.target.value))
                            }}>
                            <option>Select</option>
                            {managers?.map(manager => (
                                <option key={manager.id} value={manager.id}>{manager.name}</option>
                            ))}
                            </select>
                        </div>
                    )}
                    {showTenantsDropdown && (
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Locations</label>
                            <select className="form-select form-control" value={tenantId ?? 'Select'} onChange={(e) => {
                                setTenantId(Number(e.target.value))
                            }}>
                            <option>Select</option>
                            {tenants?.map(tenant => (
                                <option key={tenant.id} value={tenant.id}>{tenant.name}</option>
                            ))}
                            </select>
                        </div>
                    )}
                    <p>Please fill out the form to send an invitation.</p>
                </form>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                >
                    Send Invitation
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default InviteModal;
