import { useMemo, useState } from "react";
import { Card, CardBody, CardFooter } from "reactstrap"
import Select from "react-select";
import { generalizeValue } from "utils";

const SendNotificationForm = ({ channels = [], onSend }: { channels: string[], onSend: Function }) => {
  const [newData, setNewData] = useState<any>({});
  const [selectedChannels, setSelectedChannels] = useState(null) as any[];

  const handleSave = () => {
    onSend && onSend({
      ...newData,
      channels: selectedChannels.map((channel) => channel.value)
    })
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const dropdownItems = useMemo(() => {
    return channels.map((channel) => (
        {
          label: channel['displayName'],
          value: channel['channel']
        }
      ))
  }, [channels]);

  const optionGroup = [
    { label: "Mustard", value: "Mustard" },
    { label: "Ketchup", value: "Ketchup" },
    { label: "Relish", value: "Relish" }
  ];

  return (
    <Card>
      <CardBody>
        <form>
          <div className="mb-3">
              <label htmlFor="title" className="form-label">Title</label>
              <input value={newData?.title ?? ''} type="text" className="form-control" id="title" placeholder="Enter title" onChange={handleFieldInput} />
          </div>
          <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <input value={newData?.message ?? ''} type="text" className="form-control" id="message" placeholder="Enter message" onChange={handleFieldInput} />
          </div>
          <div className="mb-3">
            <label htmlFor="channel" className="form-label">Channels</label>
            <Select
              value={selectedChannels}
              isMulti={true}
              onChange={(e) => {
                setSelectedChannels(e);
              }}
              options={dropdownItems}
              className="select2-selection"
            />
          </div>
        </form>
      </CardBody>
      <CardFooter>
        <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
        >
            Send
        </button>
      </CardFooter>
    </Card>
  )
};

export default SendNotificationForm;