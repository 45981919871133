import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {deleteClientReport, getClientsReports, getManagers} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { convertUTC2Local } from "utils";
import { Link } from "react-router-dom";
import DeleteModal from "Components/Modal/DeleteModal";
import ReportPreviewModal from "Components/Modal/ReportPreviewModal";
import Flatpickr from 'react-flatpickr';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/plugins/monthSelect/style.css"


interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const ReportClientPage = ({title}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [reportsData, setReportsData] = useState<any[]>([]);
    const [dateFilter, setDateFilter] = useState<Date>(new Date());
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function togglePreviewModal () {
        setPreviewModalIsOpen(!previewModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleDeleteReport = () => {
        const removeReport = async () => {
            try {
                const response = await deleteClientReport(valueToBeModified.clientId, valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setReportsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove report.");
                }
            } catch (error) {

                toast.error("Failed to remove report.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeReport()
        }
    }

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await getClientsReports(dateFilter.getFullYear(), dateFilter.getMonth() + 1); // Add parameters if needed
                if (response.success) {

                    setReportsData(response.data.reports);
                } else {
                    // Display error notification
                    setReportsData([]);
                    toast.error(response.error || "No reports are retrieved.");
                }
            } catch (error) {
                setReportsData([]);
                toast.error("No reports are retrieved.");
            }
        };

        fetchReports();
    }, [dateFilter]);

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'client.name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Phone Number',
                accessorKey: 'phoneNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Work Status',
                accessorKey: 'reportData.work',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return cellProps.getValue() ? 1 : 0
                }
            },
            {
                header: 'School Status',
                accessorKey: 'reportData.school',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return cellProps.getValue() ? 1 : 0
                }
            },
            {
                header: 'Support Services Status',
                accessorKey: 'reportData.supportServices',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return cellProps.getValue() ? 1 : 0
                }
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                enableColumnFilter: false,
                cell: (info) => <div className="wrap-text">{info.getValue()}</div>,
                enableSorting: true,
            },
            {
                header: 'Submitted',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true
            },
            {
                header: 'Files',
                accessorKey: 'files',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-primary" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                togglePreviewModal()
                            }}>
                            <i className="mdi mdi-file font-size-18" id="fileTooltip" />
                            <UncontrolledTooltip placement="top" target="fileTooltip">
                                Preview
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        []
    );

    const dateFilterComponent = useMemo(() => {
        return (
            <Row className="justify-content-end">
                <Col className="d-flex align-items-center" lg="auto" md="auto">
                    <div className="p-2" style={{ margin: 'auto', textAlign: 'center', whiteSpace: 'nowrap' }}>Filter by Month</div>
                    <Flatpickr
                        type="month"
                        className="form-control"
                        value={dateFilter}
                        options={{
                            dateFormat: 'F Y', // Format to display the selected month
                            altFormat: 'F Y', // Format to display in the input field
                            altInput: true,
                            mode: 'single',
                            plugins: [
                                monthSelectPlugin({
                                    shorthand: true, //defaults to false
                                    dateFormat: "m.y", //defaults to "F Y"
                                    altFormat: "F Y", //defaults to "F Y"
                                })
                            ]
                        }}
                        onChange={(e: any) => {
                            setDateFilter(e[0])
                        }}
                    />
                </Col>
            </Row>
        )
    }, [dateFilter]);

    //meta title
    document.title = `${title} | Eckerd Connects - Reports`;

    return (
        <div className="page-content">
             <ToastContainer />
             <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    showExportButtons={true}
                                                    data={reportsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    SearchPlaceholder="Search reports..."
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customRightTools={
                                                        dateFilterComponent
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <ReportPreviewModal
                                isOpen={previewModalIsOpen}
                                toggle={togglePreviewModal}
                                data={valueToBeModified?.reportFileUris}
                            />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this report? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteReport}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ReportClientPage;
