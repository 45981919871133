import React from "react";

type BadgeCellProps = {
    value: string;
  };

  const BadgeCell: React.FC<BadgeCellProps> = ({ value, badgeColor = 'primary' }: { value: string, badgeColor?: 'primary' | 'success' | 'info' | 'danger'}) => {
    console.log('Rendering RoleCell with value:', value);
    return  <span className={`badge-soft-${badgeColor} ms-1 badge bg-${badgeColor} rounded-pill`}>
    {value}
  </span>;
  };

export default React.memo(BadgeCell);
