import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Eckerd Connects.</Col>
            <Col md={6}>
              <div className="text-sm-end d-sm-block">
                For technical support, please contact us at <a href="mailto:app-support@ec-client.eckerd.org?subject=Technical Support Request">app-support@ec-client.eckerd.org</a>.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
