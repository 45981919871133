
import config from '../env.config';
//BASE URL

export const BASE_URL = config.BASE_URL;

//PROFILE
export const GET_ME = "/me";
//INVITATIONS
export const GET_INVITATIONS = "/invites";
export const NEW_INVITE = "/invites";
export const DELETE_INVITE = "/invites";
export const GET_MANAGERS = "/managers";
export const UPDATE_MANAGERS  = "/managers";
export const DELETE_MANAGERS = "/managers";
export const GET_ADMINS = "/admins";
export const UPDATE_ADMINS = "/admins";
export const DELETE_ADMINS = "/admins";
export const GET_CLIENTS = "/clients";
export const UPDATE_CLIENTS = "/clients";
export const DELETE_CLIENTS = "/clients";
export const GET_RESOURCE_CATEGORIES = "/resource-categories";

export const GET_LOCATIONS = "/locations";
export const GET_CORPS = "/corp-admins";
export const CORP_ADMINS = "/corp-admins";


export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

export const GET_EVENTS = "/clients/1024/schedules/events";
export const GET_CATEGORIES = "/goals";

export const GET_GOAL_STEPS = "/goals/{goalId}/goal-steps";

export const GET_GOAL_STEP_ACTIVITY = "/goals/{goalId}/goal-steps/{goalStepId}/activities";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

// Chat
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "add/message";
export const DELETE_MESSAGE = "delete/message";

// Ecommerce
// Product
export const GET_PRODUCTS = "/apps/product";
export const GET_PRODUCTS_DETAIL = "/apps/productDetail";

// Orders
export const GET_ORDERS = "/apps/order";
export const ADD_NEW_ORDER = "/apps/order";
export const UPDATE_ORDER = "/apps/order";
export const DELETE_ORDER = "/apps/order";

// Customers
export const GET_CUSTOMERS = "/apps/customer";
export const ADD_NEW_CUSTOMER = "/apps/customer";
export const UPDATE_CUSTOMER = "/apps/customer";
export const DELETE_CUSTOMER = "/apps/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//cart

export const GET_CART = "/cart";
export const DELETE_CART = "/delete-cart";

//CRYPTO
export const GET_WALLET = "/wallet";
//activities
export const GET_WALLET_ACTIVITIES = "wallet-activies";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/projects";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

// Task
export const GET_TASKS = "/apps/task";
export const ADD_TASKS = "/add/task";
export const UPDATE_TASKS = "/update/task";
export const DELETE_TASKS = "/delete/task";

//CONTACTS
export const GET_USERS = "/apps/users";
export const ADD_NEW_USERS = "/apps/users";
export const UPDATE_USERS = "/apps/users";
export const DELETE_USERS = "/delete/users/";
export const GET_USER_PROFILE = "/user";

// Contact
export const GET_CONTACTS = "/apps/contact";

// Crypto
export const GET_CRYPTO_ORDRER_LIST = "/crypto-order-list";

// Invoice
export const GET_INVOICES = "/apps/invoice";
export const GET_INVOICE_DETAIL = "/apps/invoice-detail";

//Mails
export const GET_INBOX_MAILS = "/mailslists";
export const SELECT_FOLDER = "/folders";
export const GET_SELECTE_MAIL = "/seelctMail"
export const UPDATE_MAIL = "/update/mail";
export const SET_FOLDER_SELECTED_MAILS = "/setfolderonmail";
export const STARED_MAIL = "/stared/mail";
export const TRASH_MAIL = "/trash/mail";
export const GET_MAILS_ID = "/mail:id"

//Delete mail
export const DELETE_MAIL = "/mail/delete";

export const GET_EARNING_DATA = "/earning-charts-data";

export const TOP_SELLING_DATA = "/top-selling-data";

//dashboard charts data
export const GET_DASHBOARD_EMAILCHART = "/dashboard/email-chart";

//latest transaction
export const GET_TRANSACTION = "/transaction";

// Wallet Balance
export const GET_WALLENT_BALANCE = "/walletBalance"

// Visitors
export const GET_BLOG_VISITORS = "/blogVisitors";

//statistics Applications
export const GET_STATISTICS_APPLICATION = "/statistics Applications"

//Jobs
export const GET_JOB_LIST = "/jobs";
export const DELETE_JOB_LIST = "/delete/job";
export const ADD_NEW_JOB_LIST = "/job/add"
export const UPDATE_JOB_LIST = "/job/update"

//job grid
export const GET_JOB_GRID = "/job-grid";
//job condidate list
export const GET_CANDIDATE0_LIST = "/condidate-list";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "/delete/applyjob";

//Chat
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";

//Announcements
export const GET_ANNOUNCEMENTS = "/announcements";
export const ADD_ANNOUNCEMENTS = "/announcements";
export const UPDATE_ANNOUNCEMENT = "/announcements";
export const DELETE_ANNOUNCEMENT = "/announcements";




//Quotes
export const GET_QUOTES = "/quotes";
export const ADD_QUOTE = "/quotes";
export const UPDATE_QUOTE = "/quotes";
export const DELETE_QUOTE = "/quotes";

//Goals
export const GET_GOALS = "/goals";
export const ADD_GOAL = "/goals";
export const UPDATE_GOAL = "/goals";
export const DELETE_GOAL = "/goals";

//Goal Steps
export const GOALS = "/goals";
export const GOALSTEPS = "/goal-steps";

//Activities
export const GOALACTIVITIES = "/activities";

//Resources
export const RESOURCE_CATEGORY = "/resource-categories";
export const RESOURCE = "/resources";

//Programs
export const PROGRAMS = "/programs";

//Rules
export const GET_RULES = "/rules";
export const ADD_RULE = "/rules";
export const UPDATE_RULE = "/rules";
export const REMOVE_RULE = "/rules";

//Rule groups
export const GET_RULEGROUPS = "/rule-groups";
export const ADD_RULEGROUP = "/rule-groups";
export const UPDATE_RULEGROUP = "/rule-groups";
export const REMOVE_RULEGROUP = "/rule-groups";

//Campaigns
export const GET_CAMPAIGNS = "/campaigns";
export const ADD_CAMPAIGNS = "/campaigns";
export const UPDATE_CAMPAIGNS = "/campaigns";
export const REMOVE_CAPAIGNS = "/campaigns";
export const STANDINGS = "/standings";

//Tenants
export const GET_TENANTS = "/tenants";
export const TENANTS = "/tenants";

//Uploads
export const FILE_UPLOAD = "/files/uploads";
export const LOGO = "/logo";
// Getting Started Videos
export const GET_STARTED_VIDEOS = "/start-videos";
export const ADD_STARTED_VIDEO = "/start-videos";
export const UPDATE_STARTED_VIDEO = "/start-videos";
export const REMOVE_STARTED_VIDEO = "/start-videos";

// Notifications
export const GET_NOTIFICATIONS = "/notifications";
export const SEND_NOTIFICATION = "/notifications";

// Notification Channels
export const GET_NOTIFICATION_CHANNELS = "/notifications/channels";

// Client Personal Goals
export const GET_CLIENT_PERSONAL_GOALS = "/clients/personal-goals";

// Client Reports
export const GET_CLIENT_REPORTS = "/clients/reports";

// Knowledge Base
export const KNOWLEDGE_BASES = "/resources/knowledge-bases";

export const GUARDRAILS = "/chatbot/guardrails";
export const GUARDRAILS_DELETE = "/chatbot/guardrails/delete";
export const CHAT_HISTORY = "/chatbot/messages/history";

// Admin Bot
export const ADMIN_BOT_MESSAGE = "/admin-bot/messages";

// Role Management
export const ROLES = "/roles";

// Admin Chatbot
export const ADMIN_CHATBOT = "/chat";

