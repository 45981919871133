import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

const DeleteModal = (modalProps: { isOpen, toggle, onConfirmDelete, message, deleteProps?: any }) => {
    const { isOpen, toggle, onConfirmDelete, message, deleteProps } = modalProps;
    const [loading, setLoading] = useState(false); // Add loading state
    // optional props to pass into the delete function for stateless components
    const handleDelete = async (props?) => {
        setLoading(true);
        await onConfirmDelete(props); // This should be a function passed down that handles the actual deletion logic.
        toggle(); // Close the modal after confirming the deletion.
        setLoading(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="deleteModalLabel">
                    Confirm Deletion
                </div>
            </ModalHeader>
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={deleteProps ? (() => {handleDelete(deleteProps)}) : handleDelete}
                    className="btn btn-danger"
                >
                    {loading ? <Spinner size="sm" /> : "Delete"}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
