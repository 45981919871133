import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    getEvents,
    getCategories,
    addNewEvent,
    deleteEvent,
    updateEvent,
    getGoalSteps,
    getGoalStepActivities, deleteAllEvents
} from "./thunk";

interface Event {
    id: number;
    scheduleId: number;
    eventId: number;
    // Define other properties of an event here
}

export interface Category {
    id: number;
    // Define category properties here
}

interface CalendarState {
    events: Event[];
    categories: Category[];
    error: any;
    addEventSuccess: boolean;
}

export const initialState: CalendarState = {
    events: [],
    categories: [],
    addEventSuccess: false,
    error: {}
};

const CalendarSlice = createSlice({
    name: 'CalendarSlice',
    initialState,
    reducers: {
        resetAddEventSuccess(state) {
            state.addEventSuccess = false;
        },
        clearEventsSuccess(state) {
            state.events = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEvents.fulfilled, (state: any, action: PayloadAction<Event[]>) => {

            state.events = action.payload;
        });
        builder.addCase(getEvents.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });

        builder.addCase(getCategories.fulfilled, (state: any, action: any) => {
            state.categories = action.payload;
        });
        builder.addCase(getCategories.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });
        builder.addCase(getGoalStepActivities.fulfilled, (state: any, action: any) => {
            state.activities = action.payload;
        });
        builder.addCase(getGoalStepActivities.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });
        builder.addCase(getGoalSteps.fulfilled, (state: any, action: any) => {
            state.steps = action.payload;
        });
        builder.addCase(getGoalSteps.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });

        builder.addCase(addNewEvent.fulfilled, (state: any, action: any) => {
            // const formattedEvent = formatEvent(action.payload);
            // console.log(action.payload,formattedEvent);
            // state.events.push(formattedEvent);
            state.addEventSuccess = true;
        });
        builder.addCase(addNewEvent.rejected, (state: any, action: any) => {

            console.log(action.payload.error);
            state.error = action.payload.error || null;
        });

        builder.addCase(updateEvent.fulfilled, (state: any, action: any) => {
            // state.events = (state.events || []).map((event: any) =>
            //     event.id.toString() === action.payload.scheduleId.toString()
            //         ? { ...event, ...action.payload }
            //         : event
            // );
            state.addEventSuccess = true;
        });

        builder.addCase(updateEvent.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
        });

        builder.addCase(deleteEvent.fulfilled, (state, action: PayloadAction<any>) => {
            // Assuming the payload is the deleted event object, use its id to filter it out from the state
            // const deletedEventId = action.payload.eventId;
            //   console.log("EVENT DELETED", deletedEventId);
            // state.events = state.events.filter((event) => {
            //     console.log("Checking event with ID:", event.eventId); // Log the ID of each event being checked
            //     return event.eventId !== deletedEventId; // Only keep events that don't match the deletedEventId
            // });
            state.addEventSuccess = true;
        });


        builder.addCase(deleteEvent.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(deleteAllEvents.fulfilled, (state, action: PayloadAction<any>) => {
            // Assuming the payload is the deleted event object, use its id to filter it out from the state
            const deletedEventId = action.payload.id;
            console.log("EVENTS DELETED", deletedEventId);
            state.events = state.events.filter((event) => event.id !== deletedEventId);
        });


        builder.addCase(deleteAllEvents.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
})
export default CalendarSlice.reducer;
export const { resetAddEventSuccess, clearEventsSuccess} = CalendarSlice.actions;
