import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FileUploader from 'Components/Common/FileUploader';
import { generalizeValue } from 'utils';

type IKnowledgeBaseModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  onUploadFile?: Function;
  toggle: () => void
}

const KnowledgeBaseModal: React.FC<IKnowledgeBaseModal> = ({ data, isOpen, isEdit, onSave, onUploadFile, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState<File[]>([]);

  const handleSave = () => {
    
    onSave && onSave(newData, knowledgeBaseFiles)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const handleUploadResouceFiles = (files: File[]) => {

    if (onUploadFile) {
      onUploadFile(files)
        .then((uploads: any[]) => {
          if (uploads?.[0]?.putUrl) {
            setNewData((state) => ({
              ...state,
              mediaUri: uploads?.[0]?.putUrl
            }))
          }
        })
    }
  }

  const handleKnowledgeBaseFileChange = (files: File[]) => {
    setKnowledgeBaseFiles(files)
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Knowledge Base Document" : "New Knowledge Base Document" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input value={newData?.name} type="text" className="form-control" id="name" placeholder="Enter name" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="description" className="form-label">Description</label>
                      <input value={newData?.description} type="text" className="form-control" id="description" placeholder="Enter description" onChange={handleFieldInput} />
                  </div>
                  <FileUploader label="Knowledge Base Document" hint="Please attach the document here." multiple={false} onSend={handleUploadResouceFiles} onChange={handleKnowledgeBaseFileChange} />
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update knowledge base" : "Create knowledge base"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default KnowledgeBaseModal;
