import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { convertUTCDateToInputFormat, dayAfter, generalizeValue } from 'utils';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_blue.css";

type ICampaignModal = {
  data?: any;
  ruleGroups?: any[];
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  toggle: () => void
}

const CampaignModal: React.FC<ICampaignModal> = ({ data, ruleGroups = [], isOpen, isEdit, onSave, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  
  const handleSave = () => {
    onSave && onSave({
      ...newData,
      startDate: newData?.startDate ?? convertUTCDateToInputFormat(null),
      endDate: newData?.endDate ?? convertUTCDateToInputFormat(null)
    })
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const handleStartInputField = (value) => {
    const date = new Date(value);
    const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '00:00:00'
    const currentDate = new Date(normalizedDateStr);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hour = String(currentDate.getUTCHours()).padStart(2, '0');
    const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const second = String(currentDate.getUTCSeconds()).padStart(2, '0');

    setNewData((state) => ({
      ...state,
      "startDate": `${year}-${month}-${day}`
    }))
  }

  const handleEndInputField = (value) => {
    const date = new Date(value);
    const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '23:59:59'
    const currentDate = new Date(normalizedDateStr);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hour = String(currentDate.getUTCHours()).padStart(2, '0');
    const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const second = String(currentDate.getUTCSeconds()).padStart(2, '0');

    setNewData((state) => ({
      ...state,
      "endDate": `${year}-${month}-${day}`
    }))
  }

  useEffect(() => {
    setNewData({})
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({
        startDate: convertUTCDateToInputFormat(null),
        endDate: convertUTCDateToInputFormat(null)
      })
    }
  }, [data, isEdit, isOpen])
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Campaign" : "New Campaign" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input value={newData?.name} type="text" required className="form-control" id="name" placeholder="Enter name" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="description" className="form-label">Description</label>
                      <input value={newData?.description} type="text" className="form-control" id="description" placeholder="Enter event" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Rule Group</label>
                    <select className="form-select form-control" value={newData?.ruleGroupId ?? 'Select'} onChange={(e) => {
                      setNewData((state) => ({...state, ruleGroupId: Number(e.target.value)}))
                    }}>
                      <option>Select</option>
                      {ruleGroups?.map(group => (
                        <option key={group.id} value={group.id}>{group.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                      <label htmlFor="startDate" className="form-label">Start At</label>
                      <Flatpickr
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="form-control"
                        options={{ minDate: isEdit ? undefined : convertUTCDateToInputFormat(null)}}
                        value={convertUTCDateToInputFormat(newData?.startDate)}
                        onChange={handleStartInputField}
                      />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="endDate" className="form-label">End At</label>
                      <Flatpickr
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="form-control"
                        options={{ minDate: dayAfter(convertUTCDateToInputFormat(newData?.startDate))}}
                        value={newData?.endDate ? convertUTCDateToInputFormat(newData?.endDate) : ''}
                        onChange={handleEndInputField}
                      />
                  </div>
                  <p>Please fill out the form to create new campaign.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update campaign" : "Create campaign"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default CampaignModal;
