import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import {
    deleteInvite,
    getInvitations,
    getTenants,
    resendInvitation,
} from "helpers/backend_helper";
import { UserRoleKeys, userManager } from "../../../utils/UserManager";
import RoleCell from "../../../Components/Common/RoleCell";
import BadgeCell from "../../../Components/Common/BadgeCell";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { Link } from "react-router-dom";
import ConfirmModal from "Components/Modal/ConfirmModal";
import DeleteModal from "../../../Components/Modal/DeleteModal";
import ExtendedTableContainer from "../../../Components/Common/ExtendedTableContainer";
import { convertUTC2Local } from "utils";

const InvitationsPage = ({ title }: { title: string }) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [invitationsData, setInvitationsData] = useState<any[]>([]);
    const [invitationStatus, setInvitationStatus] = useState(1);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [tenantFilterId, setTenantFilterId] = useState<string>();
    const [tenantsData, setTenantsData] = useState<any[]>([]);
    const [invitationRole, setInvitationRole] = useState<UserRoleKeys>();
    const [resendModalIsOpen, setResendModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const role = userManager.getUserRole();

    useEffect(() => {
        if (role === UserRoleKeys.RoleAdmin) {
            setInvitationRole(UserRoleKeys.RoleManager);
        } else if (role === UserRoleKeys.RoleCorp) {
            setInvitationRole(UserRoleKeys.RoleCorp);
        } else {
            setInvitationRole(UserRoleKeys.RoleNone);
        }
    }, [role]);

    useEffect(() => {
        if (role === UserRoleKeys.RoleCorp) {
            const fetchTenants = async () => {
                try {
                    const response = await getTenants(1);
                    if (response.success) {
                        setTenantsData(response.data.tenants);
                        setTenantFilterId(response.data.tenants[0]?.dbName);
                    } else {
                        toast.error(response.error || "Failed to fetch tenants.");
                    }
                } catch (error) {
                    toast.error("Failed to fetch tenants.");
                }
            };

            fetchTenants();
        }
    }, [role]);

    useEffect(() => {
        const fetchInvitations = async () => {
            setLoading(true);
            try {
                const offset = currentPage * pageSize;
                const tenantId = (role === UserRoleKeys.RoleCorp && invitationRole === UserRoleKeys.RoleAdmin) ? tenantFilterId : undefined;

                const response = await getInvitations(
                    invitationRole,
                    tenantId,
                    invitationStatus,
                    offset,
                    pageSize
                );
                console.log(tenantFilterId, role);
                if (response.success) {
                    setInvitationsData(response.data.invites);
                    setTotalResults(response.data.totalResults);
                } else {
                    toast.error(response.error || "Failed to fetch invitations.");
                }
            } catch (error) {
                toast.error("Failed to fetch invitations.");
            } finally {
                setLoading(false);
            }
        };

        fetchInvitations();
    }, [invitationStatus, invitationRole, tenantFilterId, currentPage, pageSize]);

    const toggleResendModal = () => setResendModalIsOpen(!resendModalIsOpen);
    const toggleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);

    const handleDeleteInvite = async () => {
        if (!valueToBeModified?.id) return;
        try {
            const response = await deleteInvite(
                valueToBeModified.id,
                valueToBeModified.role ?? "",
                tenantFilterId ?? ""
            );
            if (response.success) {
                setInvitationsData((prev) =>
                    prev.filter((invite) => invite.id !== valueToBeModified.id)
                );
                toast.success("Invitation deleted successfully!");
            } else {
                toast.error(response.error || "Failed to delete invitation.");
            }
        } catch {
            toast.error("Failed to delete invitation.");
        }
    };

    const handleConfirmResend = async () => {
        if (!valueToBeModified?.id) return;
        try {
            const response = await resendInvitation(
                valueToBeModified.role,
                valueToBeModified.id,
                tenantFilterId ?? ""
            );
            if (response.success) {
                toast.success("Invitation resent successfully!");
            } else {
                toast.error(response.error || "Failed to resend invitation.");
            }
        } catch {
            toast.error("Failed to resend invitation.");
        }
    };

    const columns = useMemo(() => {
        return [
            {
                header: "Invite ID",
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Email Invited",
                accessorKey: "emailInvited",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Invited Name",
                accessorKey: "nameInvited",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Expiration Date",
                accessorKey: "expirationDate",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (info: any) => <>{convertUTC2Local(info.getValue())}</>,
            },
            {
                header: "Action",
                accessorKey: "action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const { id } = cellProps.row.original;
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-primary"
                                onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleResendModal();
                                }}
                            >
                                <i className="mdi mdi-send font-size-18" id={`resendTooltip-${id}`} />
                                <UncontrolledTooltip target={`resendTooltip-${id}`}>
                                    Resend
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleDeleteModal();
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id={`deleteTooltip-${id}`} />
                                <UncontrolledTooltip target={`deleteTooltip-${id}`}>
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ];
    }, []);
    const customLeftTools = useMemo(() => {
        if (role === UserRoleKeys.RoleCorp && invitationRole === UserRoleKeys.RoleAdmin) {
            return (
                <React.Fragment>
                    <select className="form-select form-control" value={tenantFilterId ?? 'Select'} onChange={(e) => {
                        setTenantFilterId(e.target.value)
                    }}>
                        <option>Select</option>
                        {tenantsData?.map(tenant => (
                            <option key={tenant.id} value={tenant.dbName}>{tenant.name}</option>
                        ))}
                    </select>
                </React.Fragment>
            )
        } else {
            return <React.Fragment />
        }
    }, [tenantFilterId, tenantsData, invitationRole, role]);
    const statusRadioGroups = useMemo(() => {
        const handleRoleChange = (newRole) => {
            setInvitationRole(newRole);
            setCurrentPage(0); // Reset pagination to the first page
        };

        const handleStatusChange = (newStatus) => {
            setInvitationStatus(newStatus);
            setCurrentPage(0); // Reset pagination to the first page
        };

        if (role === "role_admin") {
            return (
                <Row className="justify-content-end" xs="auto">
                    <Col className="btn-group" role="role">
                        <input
                            type="radio"
                            className="btn-check"
                            name="role"
                            id="role_manager"
                            autoComplete="off"
                            checked={invitationRole === UserRoleKeys.RoleManager}
                            onChange={() => handleRoleChange(UserRoleKeys.RoleManager)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="role_manager">
                            Manager
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="role"
                            id="role_client"
                            autoComplete="off"
                            checked={invitationRole === UserRoleKeys.RoleNone}
                            onChange={() => handleRoleChange(UserRoleKeys.RoleNone)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="role_client">
                            Client
                        </label>
                    </Col>
                    <Col className="btn-group" role="status">
                        <input
                            type="radio"
                            className="btn-check"
                            name="status"
                            id="accepted"
                            autoComplete="off"
                            checked={invitationStatus === 1}
                            onChange={() => handleStatusChange(1)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="accepted">
                            Accepted
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="status"
                            id="notaccepted"
                            autoComplete="off"
                            checked={invitationStatus === 0}
                            onChange={() => handleStatusChange(0)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="notaccepted">
                            Not Accepted
                        </label>
                    </Col>
                </Row>
            );
        } else if (role === "role_corp") {
            return (
                <Row className="justify-content-end" xs="auto">
                    <Col className="btn-group" role="role">
                        <input
                            type="radio"
                            className="btn-check"
                            name="role"
                            id="role_corp"
                            autoComplete="off"
                            checked={invitationRole === UserRoleKeys.RoleCorp}
                            onChange={() => handleRoleChange(UserRoleKeys.RoleCorp)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="role_corp">
                            Corporate
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="role"
                            id="role_admin"
                            autoComplete="off"
                            checked={invitationRole === UserRoleKeys.RoleAdmin}
                            onChange={() => handleRoleChange(UserRoleKeys.RoleAdmin)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="role_admin">
                            Administrator
                        </label>
                    </Col>
                    <Col className="btn-group" role="status">
                        <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="accepted"
                            autoComplete="off"
                            checked={invitationStatus === 1}
                            onChange={() => handleStatusChange(1)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="accepted">
                            Accepted
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="notaccepted"
                            autoComplete="off"
                            checked={invitationStatus === 0}
                            onChange={() => handleStatusChange(0)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="notaccepted">
                            Not Accepted
                        </label>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="justify-content-end" xs="auto">
                    <Col className="btn-group" role="status">
                        <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="accepted"
                            autoComplete="off"
                            checked={invitationStatus === 1}
                            onChange={() => handleStatusChange(1)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="accepted">
                            Accepted
                        </label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="notaccepted"
                            autoComplete="off"
                            checked={invitationStatus === 0}
                            onChange={() => handleStatusChange(0)}
                        />
                        <label className="btn btn-outline-secondary" htmlFor="notaccepted">
                            Not Accepted
                        </label>
                    </Col>
                </Row>
            );
        }
    }, [role, invitationRole, invitationStatus]);



    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title} />
                <Row>
                    <Col lg={12}>
                        {isLoading ? (
                            <Spinners setLoading={setLoading} />
                        ) : (
                            <Card>
                                <CardBody>
                                    <ExtendedTableContainer
                                        columns={columns}
                                        data={invitationsData}
                                        isGlobalFilter
                                        isPagination
                                        isCustomPageSize={true}
                                        currentPage={currentPage}
                                        pageSize={pageSize}
                                        totalResults={totalResults}
                                        onPageChange={setCurrentPage}
                                        onPageSizeChange={(size) => {
                                            console.log("Page size changed:", size); // Debug statement
                                            setPageSize(size);
                                            setCurrentPage(0);
                                        }}
                                        SearchPlaceholder="Search invitations..."
                                        customRightTools={statusRadioGroups}
                                        customLeftTools={customLeftTools}

                                    />
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Container>
            <ConfirmModal
                isOpen={resendModalIsOpen}
                toggle={toggleResendModal}
                message={<p>Are you sure you want to resend the invitation?</p>}
                onConfirm={handleConfirmResend}
            />
            <DeleteModal
                isOpen={deleteModalIsOpen}
                toggle={toggleDeleteModal}
                message={
                    <p>Are you sure you want to delete this invitation? This action cannot be undone.</p>
                }
                onConfirmDelete={handleDeleteInvite}
            />
        </div>
    );
};

export default InvitationsPage;
