import React from "react";
import { Container } from "reactstrap";

import UserChat from "../../../slices/chat/ChatWindow";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

const ChatPage: React.FC<{}> = () => {
  //meta title
  document.title = `Chat | Admin App Support`;
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Admin Support" breadcrumbItem="Chat" />

                <UserChat
                  Chat_Box_Username="Admin Support"
                  Chat_Box_User_Status="online"
                  messages={[]}
                  loading={false}
                  chatType="support"
                  client={{}}
                />
        </Container>
      </div >
    </React.Fragment >
  );
};



export default ChatPage;
