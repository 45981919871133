import { getFirebaseBackend } from "helpers/firebase_helper";
import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import {userManager} from "../../../utils/UserManager";

export const loginuser = (user: any, history: any) => {
    return async (dispatch: any) => {
        try {

             if (process.env.REACT_APP_DEFAULTAUTH === "cognito") {
                try {
                    await userManager.loginUser(user.email, user.password, dispatch , history);
                } catch (error) {
                    const errorMessage = getErrorMessage(error);
                    dispatch(apiError(errorMessage));
                }

            }

        } catch (error) {

            console.log(error);

        }
    };
}
export function getErrorMessage(error: unknown): string {
    return error instanceof Error ? error.message : 'An unknown error occurred';
}
export const logoutUser = () => async (dispatch: any) => {
    try {
        localStorage.removeItem("authUser");

        const fireBaseBackend = getFirebaseBackend();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = fireBaseBackend.logout;
            dispatch(logoutUserSuccess(response));
        } else {
            dispatch(logoutUserSuccess(true));
        }

    } catch (error) {
        dispatch(apiError(error));
    }
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};


export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);
        }

        const socialdata = await response;
        if (socialdata) {
            sessionStorage.setItem("authUser", JSON.stringify(socialdata));
            dispatch(loginSuccess(socialdata));
            history('/');
        }

    } catch (error) {
        dispatch(apiError(error));
    }
};
