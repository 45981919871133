import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addProgram, deleteProgram, getPrograms, updateProgram} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import ProgramModal from "Components/Modal/ProgramModal";
import { convertUTC2Local } from "utils";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const ProgramManagementPage = ({title}) => {
    const navigate = useNavigate();
    const [programsData, setProgramsData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateProgram = (newProgram: any) => {
        const createProgram = async () => {
            try {
                const response = await addProgram(newProgram); // Add parameters if needed
                if (response.success) {

                    setProgramsData((state) => [...state, response.data]);
                    toast.success("Created new program.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create program.");
                }
            } catch (error) {

                toast.error("Failed to create program.");
            }
        };

        createProgram();
    }
    function prepareProgramData(programData: any) {
        const { lastModifiedBy, createdAt, updatedAt, deletedAt, id, ...cleanData } = programData;
        return cleanData;
    }
    const handleEditProgram = (originalProgramData: any) => {
        const modifyProgram = async () => {
            try {
                // Preparing the program data for the API request
                const program = prepareProgramData(originalProgramData);
                const response = await updateProgram(program, originalProgramData.id); // Add parameters if needed
                if (response.success) {

                    setProgramsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === originalProgramData.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = originalProgramData
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update program.");
                }
            } catch (error) {

                toast.error("Failed to update program.");
            }
        };

        modifyProgram();
    }

    const handleDeleteProgram = () => {
        const removeProgram = async () => {
            try {
                const response = await deleteProgram(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setProgramsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove program.");
                }
            } catch (error) {

                toast.error("Failed to remove program.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeProgram()
        }
    }

    useEffect(() => {
        const fetchPrograms = async () => {
            try {
                const response = await getPrograms(1); // Add parameters if needed
                if (response.success) {

                    setProgramsData(response.data.programs);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch programs.");
                }
            } catch (error) {

                toast.error("Failed to fetch programs.");
            }
        };
        fetchPrograms();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={programsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search programs..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Program"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <ProgramModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateProgram} />
                            <ProgramModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditProgram} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this program? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteProgram}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ProgramManagementPage;
