import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generalizeValue } from 'utils';

type IRuleGroupModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  toggle: () => void
}

const RuleGroupModal: React.FC<IRuleGroupModal> = ({ data, isOpen, isEdit, onSave, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  
  const handleSave = () => {
    onSave && onSave(newData)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Group" : "New Group" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input value={newData?.name} type="text" className="form-control" id="name" placeholder="Enter name" onChange={handleFieldInput} />
                  </div>
                  <p>Please fill out the form to create new group.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update group" : "Create group"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default RuleGroupModal;
