import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generalizeValue } from 'utils';

type IProgramModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  toggle: () => void
}

const ProgramModal: React.FC<IProgramModal> = ({ data, isOpen, isEdit, onSave, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  
  const handleSave = () => {
    onSave && onSave(newData)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Program" : "New Program" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input value={newData?.name} type="text" className="form-control" id="name" placeholder="Enter name" onChange={handleFieldInput} />
                  </div>
                  {/* <div className="form-check mb-3">
                    <input
                      checked={newData?.defaultSettings?.push_notifications ?? false}
                      className="form-check-input"
                      type="checkbox"
                      id="push_notifications"
                      onChange={() => {
                        setNewData((state) => ({
                          ...state,
                          "defaultSettings": {
                            ...(state?.defaultSettings ?? {}),
                            "push_notifications": !state?.defaultSettings?.push_notifications
                          }
                        }))
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="push_notifications"
                    >
                      Push Notifications Enabled for Admin
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      checked={newData?.defaultSettings?.case_manager_invite ?? false}
                      className="form-check-input"
                      type="checkbox"
                      id="case_manager_invite"
                      onChange={() => {
                        setNewData((state) => ({
                          ...state,
                          "defaultSettings": {
                            ...(state?.defaultSettings ?? {}),
                            "case_manager_invite": !state?.defaultSettings?.case_manager_invite
                          }
                        }))
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="case_manager_invite"
                    >
                      Case Managers Can Send Client Invites
                    </label>
                  </div>
                  <div className="mb-3">
                      <label htmlFor="use_coach_name" className="form-label">Coach/Check in section title</label>
                      <input value={newData?.defaultSettings?.use_coach_name} type="text" className="form-control" id="use_coach_name" placeholder="Enter coach name"
                        onChange={(e) => {
                          setNewData((state) => ({
                            ...state,
                            "defaultSettings": {
                              ...(state?.defaultSettings),
                              "use_coach_name": e.target.value
                            }
                          }))
                        }}
                      />
                  </div> */}
                  <p>Please fill out the form to create new program.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update program" : "Create program"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default ProgramModal;
