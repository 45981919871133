import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FileUploader from 'Components/Common/FileUploader';
import Flatpickr from 'react-flatpickr';
import { convertUTCDateToInputFormat, dayAfter, generalizeValue } from 'utils';
import "flatpickr/dist/themes/material_blue.css";

type IAnnouncementModal = {
    data?: any;
    isOpen: boolean;
    isEdit?: boolean;
    onSave?: Function;
    onUploadFile?: Function;
    toggle: () => void;
};

const AnnouncementModal: React.FC<IAnnouncementModal> = ({ data, isOpen, isEdit, onSave, onUploadFile, toggle }) => {
    const [newData, setNewData] = useState<any>({});
    const [announcementFiles, setAnnouncementFiles] = useState<File[]>([]);
    const [isMediaUriManuallySet, setIsMediaUriManuallySet] = useState(false);

    const toggleW = () => toggle();

    const handleSave = () => {
        const payload = { ...newData };

        if (!payload.startDate) {
            const value = new Date();
            payload['startDate'] = `${value.getUTCFullYear()}-${String(value.getUTCMonth() + 1).padStart(2, '0')}-${String(value.getUTCDate()).padStart(2, '0')} 00:00:00`;
        }

        if (!payload.expirationDate || isNaN(new Date(payload.expirationDate).getTime())) {
            payload['expirationDate'] = null;
        }

        onSave && onSave(payload, announcementFiles);
    };

    const handleFieldInput = (e) => {
        const generalizedValue = generalizeValue(e.target.type, e.target.value);
        if (e.target.id === 'mediaUri' && e.target.type === 'url') {
            setIsMediaUriManuallySet(true);
            setAnnouncementFiles([]); // Clear files if manually entering a URL
        }

        setNewData((state) => ({
            ...state,
            [e.target.id]: generalizedValue,
        }));
    };

    const handleStartInputField = (value) => {
        const date = new Date(value);
        const formattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')} 00:00:00`;

        setNewData((state) => ({
            ...state,
            startDate: formattedDate,
        }));
    };

    const handleEndInputField = (value) => {
        const date = new Date(value);
        const formattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')} 23:59:59`;

        setNewData((state) => ({
            ...state,
            expirationDate: formattedDate,
        }));
    };

    const handleUploadResouceFiles = (files: File[]) => {
        if (!files || files.length === 0 || isMediaUriManuallySet) return;

        if (onUploadFile) {
            onUploadFile(files).then((uploads: any[]) => {
                if (uploads?.[0]?.putUrl) {
                    setNewData((state) => ({
                        ...state,
                        mediaUri: uploads[0].putUrl,
                    }));
                }
                setIsMediaUriManuallySet(false); // Reset flag after successful upload
            });
        }
    };

    const handleResourceFileChange = (files: File[]) => {
        setNewData((state) => ({
            ...state,
            mediaUri: null, // Clear mediaUri if a file is selected
        }));
        setAnnouncementFiles(files);
    };

    useEffect(() => {
        if (isEdit) {
            setNewData({ ...data });
        } else {
            setNewData({});
        }
    }, [data, isEdit, isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggleW}>
            <ModalHeader toggle={toggleW}>
                <div className="modal-title mt-0 h5">{isEdit ? 'Update Announcement' : 'New Announcement'}</div>
            </ModalHeader>
            <ModalBody>
                <form>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input value={newData?.title} type="text" className="form-control" id="title" placeholder="Enter title" onChange={handleFieldInput} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <input value={newData?.description} type="text" className="form-control" id="description" placeholder="Enter description" onChange={handleFieldInput} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">Start At</label>
                        <Flatpickr
                            className="form-control"
                            options={{ minDate: isEdit ? undefined : convertUTCDateToInputFormat(null) }}
                            value={convertUTCDateToInputFormat(newData?.startDate)}
                            onChange={handleStartInputField}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="expirationDate" className="form-label">Expire At</label>
                        <Flatpickr
                            className="form-control"
                            options={{ minDate: dayAfter(convertUTCDateToInputFormat(newData?.startDate)) }}
                            value={newData?.expirationDate ? convertUTCDateToInputFormat(newData?.expirationDate) : ''}
                            onChange={handleEndInputField}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mediaUri" className="form-label">Media URI</label>
                        <input value={newData?.mediaUri} type="url" className="form-control" id="mediaUri" placeholder="Enter media URL or upload resource file" onChange={handleFieldInput} />
                    </div>
                    <FileUploader label="Announcement Files" hint="Please upload files." multiple={false} onSend={handleUploadResouceFiles} onChange={handleResourceFileChange} />
                    <p>Please fill out the form to create a new announcement.</p>
                </form>
            </ModalBody>
            <ModalFooter>
                <button type="button" onClick={toggleW} className="btn btn-secondary">
                    Close
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSave}>
                    {isEdit ? 'Update Announcement' : 'Create Announcement'}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default AnnouncementModal;
