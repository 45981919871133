import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"

const FileUploader = ({label = "File", hint = "Please upload files with image previews", onSend, onChange, ...props}) => {
  const [selectedFiles, setselectedFiles] = useState<any>([])

  function handleAcceptedFiles(files:any) {
    files.map((file:any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
    onChange && onChange(files)
  }

  const handleSendFile = () => {
    onSend && onSend(selectedFiles)
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes:any, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="p-0">
                <h6 className="card-title">{label}</h6>
                <div className="card-title-desc">
                  {" "}
                  {hint}
                </div>
                <Dropzone
                  onDrop={(acceptedFiles:any) => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }:any) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} multiple={false} {...props} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f:any, i:any) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>

                <div className="text-center mt-4" style={{ display: 'none' }}>
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={handleSendFile}
                  >
                    Send Files
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default FileUploader
