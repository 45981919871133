import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { convertUTCDateToInputFormat, generalizeValue } from 'utils';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_blue.css";

type IClientModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  toggle: () => void
}

const ClientModal: React.FC<IClientModal> = ({ data, isOpen, isEdit, onSave, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  
  const handleSave = () => {
    onSave && onSave(newData)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const handleDateInputField = (value) => {
    const date = new Date(value);
    const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '00:00:00'
    const currentDate = new Date(normalizedDateStr);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hour = String(currentDate.getUTCHours()).padStart(2, '0');
    const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const second = String(currentDate.getUTCSeconds()).padStart(2, '0');

    setNewData((state) => ({
      ...state,
      "startDate": `${year}-${month}-${day}`
    }))
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])
  
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Client" : "New Client" }
              </div>
          </ModalHeader>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
              <form className="">
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input value={newData?.name} type="text" className="form-control" id="name" placeholder="Enter name" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="name" className="form-label">Phone Number</label>
                      <input value={newData?.phoneNumber} type="text" className="form-control" id="phoneNumber" placeholder="Enter phone number" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="dateOfBirth" className="form-label">Birth Date</label>
                      <Flatpickr
                        type="date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        className="form-control"
                        options={{ maxDate: convertUTCDateToInputFormat(null)}}
                        value={convertUTCDateToInputFormat(newData?.dateOfBirth)}
                        onChange={handleDateInputField}
                      />
                  </div>
                  <p>Please fill out the form to create new client.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update client" : "Create client"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default ClientModal;
