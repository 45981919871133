import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ReassignManagerModal = ({ selectedManagerId, managers = [] as any[], isOpen, toggle, onSave }) => {
    const [managerId, setManagerId] = useState<number>();

    const handleSave = () => {
        onSave(managerId)
    }

    useEffect(() => {
        setManagerId(selectedManagerId)
    }, [selectedManagerId, isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    Reassign Manager
                </div>
            </ModalHeader>
            <ModalBody>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Managers</label>
                        <select className="form-select form-control" value={managerId ?? 'Select'} onChange={(e) => {
                            setManagerId(Number(e.target.value))
                        }}>
                        <option>Select</option>
                        {managers?.map(manager => (
                            <option key={manager.id} value={manager.id}>{manager.name}</option>
                        ))}
                        </select>
                    </div>
                    <p>You can reassign this Client to a new Manager.</p>
                </form>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                >
                    Reassign
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ReassignManagerModal;
