export function convertUTC2Local (utcDateStr: string) {
  const utcDate = new Date(utcDateStr + " UTC");

  if (isNaN(utcDate.getTime())) {
    // Date is invalid, return an empty string
    return '';
  }
  
  return utcDate.toLocaleString()
}

export const generalizeValue = (type, value) => {
  if (type === 'datetime-local') {
    const currentDate = new Date(value);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hours = String(currentDate.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else if (type === 'date') {
    const date = new Date(value);
    const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '12:00:00'
    const currentDate = new Date(normalizedDateStr);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    // const hour = String(currentDate.getUTCHours()).padStart(2, '0');
    // const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
    // const second = String(currentDate.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`
  } else if (type === 'number') {
    if(!value && value !== 0) {
      return null
    }
    return Number(value)
  }

  return value
}

export const convertUTCDateToInputFormat = (utcDateStr) => {
  const utcDate = new Date(utcDateStr ? utcDateStr + " UTC" : new Date());

  const year = utcDate.getFullYear();
  const month = String(utcDate.getMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`
}

export const dayAfter = (date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);

  return newDate.toISOString().slice(0, 10);
}