import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Dropdown, DropdownItem, Label, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, Row, UncontrolledAlert, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import SimpleBar from "simplebar-react";
import Spinners from "Components/Common/Spinner";
import { postAdminBotMessage } from "../../helpers/backend_helper";

interface Props {
    Chat_Box_Username: string;
    Chat_Box_User_Status?: string;
    messages: any[];
    filteredMessages?: any;
    loading: boolean;
    chatType: "support" | "chatbotHistory";
    client?: any;
}

const UserChat: React.FC<Props> = ({ Chat_Box_Username, Chat_Box_User_Status, messages, filteredMessages, loading, chatType, client }) => {

    const scrollRef = useRef<any>(null);

    const DEFAULT_FILTER_VALUE = "None";

    const [copyMsgAlert, setCopyMsgAlert] = useState<boolean>(false);
    const [search_Menu, setSearch_Menu] = useState<boolean>(false);
    const [filtersMenuOpen, setSettings_Menu] = useState<boolean>(false);
    const [other_Menu, setOther_Menu] = useState<boolean>(false);
    const [isDisable, setDisable] = useState<boolean>(false);
    const [curMessage, setCurMessage] = useState<string>("");
    const [currentFilter, setCurrentFilter] = useState<string>(DEFAULT_FILTER_VALUE);
    const [displayedMessages, setDisplayedMessages] = useState<any[]>(messages);

    //Toggle Chat Box Menus
    const toggleSearch = () => setSearch_Menu(!search_Menu);

    const toggleFiltersMenu = () => {
      setSettings_Menu(!filtersMenuOpen);
    };

    const toggleOther = () => setOther_Menu(!other_Menu);

    const filterOptions = Object.keys(filteredMessages ?? {});

    const handleFilterChange = (event) => {
      const selectedFilter = event?.target?.value ?? DEFAULT_FILTER_VALUE;
      setCurrentFilter(selectedFilter === currentFilter ? DEFAULT_FILTER_VALUE : selectedFilter);
      setSettings_Menu(!filtersMenuOpen);
    };

    useEffect(() => {
      if (currentFilter === DEFAULT_FILTER_VALUE) {
        setDisplayedMessages(messages);
      } else {
        setDisplayedMessages(filteredMessages[currentFilter]);
      }
    }, [currentFilter]);

    // scroll simple bar
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.getScrollElement().scrollTop = scrollRef.current.getScrollElement().scrollHeight;
        }
    }, [displayedMessages]);
    useEffect(() => {
      if (scrollRef.current) {
          scrollRef.current.getScrollElement().scrollTop = scrollRef.current.getScrollElement().scrollHeight;
      }
  }, []);

    // ensure a hook will update displayed messages when the messages are re-rendered
    useEffect(() => {
      setDisplayedMessages(messages);
    }, [messages]);

    // copy msg
    const copyMsg = (ele: any) => {
        var copyText = ele.closest(".conversation-list").querySelector("p").innerHTML;
        navigator.clipboard.writeText(copyText);
        setCopyMsgAlert(true)
        if (copyText) {
            setTimeout(() => {
                setCopyMsgAlert(false)
            }, 1000)

        }
    };

    // search
    const handelSearch = () => {
        const searchInput = document.getElementById("searchMessage") as HTMLInputElement;
        const searchFilter = searchInput.value.toUpperCase();
        const searchUL = document.getElementById("users-conversation") as HTMLInputElement;
        const searchLI = searchUL.getElementsByTagName("li");

        Array.prototype.forEach.call(searchLI, (search: HTMLElement) => {
            const a = search.getElementsByTagName("p")[0] || '';
            const txtValue = a.textContent || a.innerText || '';

            if (txtValue.toUpperCase().indexOf(searchFilter) > -1) {
                search.style.display = "";
            } else {
                search.style.display = "none";
            }
        });
    };

    const sendChat = async (latestMessages, message) => {
      let result: any[] = [];
      try {
        result = await postAdminBotMessage(message);
        console.log(result);
      } catch (err) {
        console.error(err);
      }
      const newBotMessages = result.map(m => ({
        message: m.message,
        user: "ai",
        metadata: {
          created_at: new Date()
        }
      }))

      setDisplayedMessages([...latestMessages, ...newBotMessages]);
      setDisable(false);
    };

    const addMessage = () => {
        if (curMessage !== "") {
          setDisable(true);
            const newHumanMessage = {
              message: curMessage,
              user: "human",
              metadata: {
                created_at: new Date()
              }
            };
            const latestMessages = [...displayedMessages, newHumanMessage];
            setDisplayedMessages(latestMessages);

            sendChat(latestMessages, curMessage);

            setCurMessage("");
        }
    };

    const onKeyPress = (e: any) => {
        const { key, value } = e;
        if (key === "Enter") {
            setCurMessage(value);
            addMessage();
        }
    };

    return (
        <React.Fragment>
            <Card className="w-100">
                <div className="p-3 border-bottom">
                    <Row>
                        <Col md={4} xs={9}>
                            <h5 className="font-size-15 mb-1">{Chat_Box_Username} </h5>

                            {Chat_Box_User_Status && <p className="text-muted mb-0">
                                <i
                                    className={
                                        Chat_Box_User_Status === "online"
                                            ? "mdi mdi-circle text-success align-middle me-2"
                                            : Chat_Box_User_Status === "intermediate"
                                                ? "mdi mdi-circle text-warning align-middle me-1"
                                                : "mdi mdi-circle align-middle me-1"
                                    }
                                />
                                {Chat_Box_User_Status === "online" ? "Active now" : "Offline"}
                            </p>}
                        </Col>
                        <Col md={8} xs={3}>
                            <ul className="list-inline user-chat-nav text-end mb-0">
                                <li className="list-inline-item d-none d-sm-inline-block">
                                    <Dropdown className="me-1" isOpen={search_Menu} toggle={toggleSearch}>
                                        <DropdownToggle className="btn nav-btn" tag="a">
                                            <i className="bx bx-search-alt-2" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-md">
                                            <Form className="p-3">
                                                <FormGroup className="m-0">
                                                    <InputGroup>
                                                        <Input type="text" placeholder="Search ..." id="searchMessage" aria-label="Recipient's username" onChange={handelSearch} />
                                                        <Button color="primary" type="submit">
                                                            <i className="mdi mdi-magnify" />
                                                        </Button>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Form>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                {chatType !== "support" && <li className="list-inline-item d-none d-sm-inline-block">
                                    <Dropdown isOpen={filtersMenuOpen} toggle={toggleFiltersMenu} className="me-1">
                                        <DropdownToggle className="btn nav-btn" tag="a">
                                            <i className="bx bx-filter" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <Label for="chat-filters" style={{paddingTop: 5}}>
                                            Filters
                                          </Label>
                                          <Input
                                            id="chat-filters"
                                            name="select-filter"
                                            type="select"
                                            defaultValue={DEFAULT_FILTER_VALUE}
                                            value={currentFilter}
                                            onChange={handleFilterChange}
                                          >
                                            <option>
                                              {DEFAULT_FILTER_VALUE}
                                            </option>
                                            {filterOptions.map(fi => <option>{fi}</option>)}
                                          </Input>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>}
                                <li className="list-inline-item">
                                    <Dropdown isOpen={other_Menu} toggle={toggleOther}>
                                        <DropdownToggle className="btn nav-btn" tag="a">
                                            <i className="bx bx-dots-horizontal-rounded" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem href="mailto:app-support@ec-client.eckerd.org">Submit Support Ticket</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div>
                    <div className="chat-conversation">
                        <SimpleBar ref={scrollRef} autoHide={false} style={{ height: "600px" }}>
                            {loading ? <Spinners setLoading={() => {}} /> :
                                <ul className="list-unstyled" id="users-conversation">
                                    {
                                        displayedMessages?.map((chat: any, inx: number) => {
                                            return (
                                                <React.Fragment key={inx}>
                                                    <li
                                                        key={inx}
                                                        className={
                                                          chat.user === "ai" ? "" : "right"
                                                        }
                                                    >
                                                        <div className="conversation-list">
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle href="#!" tag="a" className="dropdown-toggle">
                                                                    <i className="bx bx-dots-vertical-rounded" />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={(e) => copyMsg(e.target)} href="#"> Copy</DropdownItem>
                                                                    {/* <DropdownItem href="#"> Forward</DropdownItem> */}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            <div className="ctext-wrap">
                                                                <div className="conversation-name">
                                                                    {chat.user === "ai" ? "Chatbot" : client.name}
                                                                </div>
                                                                <p style={{whiteSpace: "pre-wrap"}}>{chat.message}</p>
                                                                {chat.metadata &&
                                                                  <p className="chat-time mb-0">
                                                                    {chat.metadata.created_at && <i className="bx bx-time-five align-middle me-1"></i>}{chat.metadata.created_at && (new Date(chat.metadata.created_at + "Z")).toLocaleString()}
                                                                    {chat.metadata.liked_at && <i className="bx bx-like align-middle me-1" style={{paddingLeft: 5, color: "green"}}></i>}
                                                                    {chat.metadata.disliked_at && <i className="bx bx-dislike align-middle me-1" style={{paddingLeft: 5, color: "red"}}></i>}
                                                                    {chat.metadata.canceled_at && <i className="bx bx-x-circle align-middle me-1" style={{paddingLeft: 5, color: "red"}}></i>}
                                                                  </p>}
                                                                {chat.metadata?.guardrail_interventions && 
                                                                  <p className="chat-time mb-0" style={{color: "red"}}>
                                                                    {/* Check for name then default to type to identify the display name of the guardrail */}
                                                                    GUARDRAILS: {chat.metadata.guardrail_interventions.map(g => g.name ?? g.type).join(", ")}
                                                                  </p>}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </React.Fragment>)
                                        })
                                    }
                                </ul>
                            }
                        </SimpleBar>
                    </div>

                    {copyMsgAlert && <UncontrolledAlert color='warning' role="alert">Message copied</UncontrolledAlert>}

                    {chatType === "support" && <div className="p-3 chat-input-section">
                        <Row>
                            <Col>
                                <div className="position-relative">
                                    <Input type="text" value={curMessage} disabled={isDisable} onKeyPress={onKeyPress} onChange={e => { setCurMessage(e.target.value) }} className="chat-input" placeholder={isDisable ? "Thinking..." : "Enter Message..."} />
                                </div>
                            </Col>
                            <Col className="col-auto">
                                <Button type="button" color="primary" disabled={isDisable} onClick={() => addMessage()} className="btn btn-primary btn-rounded chat-send w-md ">
                                    <span className="d-none d-sm-inline-block me-2"> Send</span>
                                    <i className="mdi mdi-send" />
                                </Button>
                            </Col>
                        </Row>
                    </div>}
                </div>
            </Card>
        </React.Fragment>
    );
}

export default UserChat;