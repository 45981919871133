import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addGoalStepActivity, addProgramGoalStepActivity, deleteGoalStepActivity, deleteProgramGoalStepActivity, getGoalStepActivities, getProgramGoalStepActivities, getSingleGoal, getSingleGoalStep, getSingleProgramGoal, getSingleProgramGoalStep, updateGoalStepActivity, updateProgramGoalStepActivity } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import { Link, useParams, useSearchParams } from "react-router-dom";
import GoalStepActivityModal from "Components/Modal/GoalStepActivityModal";
import { convertUTC2Local } from "utils";
import { UserRoleKeys, userManager } from "utils/UserManager";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const GoalStepActivitiesPage = (props) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const userRole = userManager.getUserRole();
    const [goalStepActivitiesData, setGoalStepActivitiesData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [goalData, setGoalData] = useState<any>({});
    const [goalStepData, setGoalStepData] = useState<any>({});

    const { goalId, goalStepId } = params;

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateGoalStepActivity = (newGoalStepActivity: any) => {
        const programId = Number(searchParams.get('programId'))
        const createGoalStepActivity = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp ?
                    await addProgramGoalStepActivity(programId, Number(goalId), Number(goalStepId), newGoalStepActivity)
                    :
                    await addGoalStepActivity(Number(goalId), Number(goalStepId), newGoalStepActivity)
                    ; // Add parameters if needed
                if (response.success) {

                    setGoalStepActivitiesData((state) => [...state, response.data]);
                    toast.success("Created new goal step activities.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create goal step activities.");
                }
            } catch (error) {

                toast.error("Failed to create goal step activities.");
            }
        };

        createGoalStepActivity();
    }

    const handleEditGoalStepActivity = (goalStepActivity: any) => {
        const programId = Number(searchParams.get('programId'))

        const modifyGoalStepActivity = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp
                    ? await updateProgramGoalStepActivity(programId, Number(goalId), Number(goalStepId), goalStepActivity)
                    : await updateGoalStepActivity(Number(goalId), Number(goalStepId), goalStepActivity); // Add parameters if needed
                if (response.success) {

                    setGoalStepActivitiesData((state) => {
                        const findIdx = state.findIndex(item => item?.id === goalStepActivity.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = goalStepActivity
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update goal step activity.");
                }
            } catch (error) {

                toast.error("Failed to update goal step activity.");
            }
        };

        modifyGoalStepActivity();
    }

    const handleDeleteGoalStepActivity = () => {
        const programId = Number(searchParams.get('programId'))

        const removeGoalStepActivity = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp
                ? await deleteProgramGoalStepActivity(programId, Number(goalId), Number(goalStepId), valueToBeModified.id)
                : await deleteGoalStepActivity(Number(goalId), Number(goalStepId), valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setGoalStepActivitiesData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove goal step activities.");
                }
            } catch (error) {

                toast.error("Failed to remove goal step activities.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeGoalStepActivity()
        }
    }

    useEffect(() => {
        const programId = Number(searchParams.get('programId'))

        if (userRole === UserRoleKeys.RoleCorp && !isNaN(programId)) {
            const fetchGoalStepActivities = async () => {
                try {
                    const response = await getProgramGoalStepActivities(programId, Number(goalId), Number(goalStepId), 1); // Add parameters if needed
                    if (response.success) {

                        setGoalStepActivitiesData(response.data.activities);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch goal step activities.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch goal step activities.");
                }
            };

            if (!isNaN(Number(goalId)) && !isNaN(Number(goalStepId))) {
                fetchGoalStepActivities();
            }
        } else if (userRole === UserRoleKeys.RoleAdmin) {
            const fetchGoalStepActivities = async () => {
                try {
                    const response = await getGoalStepActivities(Number(goalId), Number(goalStepId), 1); // Add parameters if needed
                    if (response.success) {

                        setGoalStepActivitiesData(response.data.activities);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch goal step activities.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch goal step activities.");
                }
            };

            if (!isNaN(Number(goalId)) && !isNaN(Number(goalStepId))) {
                fetchGoalStepActivities();
            }
        }
    }, [goalId, goalStepId]);

    const breadcrumbItems: any[] = useMemo(
      () => {
        const programId = Number(searchParams.get('programId'))

        return [
            {
                link: '/goals',
                title: 'Goals'
            },
            {
                link: userRole === UserRoleKeys.RoleCorp ? `/goals/${goalId}?programId=${programId}` : `/goals/${goalId}`,
                title: `${goalData.title ?? ''} Step`
            }
        ]
    },
      [goalId, searchParams, userRole, goalData]
    );

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Title',
                accessorKey: 'title',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Description',
                accessorKey: 'description',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <button className="btn text-success p-0" onClick={() => {
                                setValueToBeModified(cellProps.row.original);
                                toggleEditModal();
                            }}>
                                <i className="mdi mdi-pencil font-size-18" id="editTooltip"/>
                                <UncontrolledTooltip placement="top" target="editTooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </button>
                            <button className="btn text-danger p-0" onClick={() => {
                                setValueToBeModified(cellProps.row.original);
                                toggleDeleteModal();
                            }}>
                                <i className="mdi mdi-delete font-size-18" id="deleteTooltip"/>
                                <UncontrolledTooltip placement="top" target="deleteTooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </button>

                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal]
    );

    useEffect(() => {
        if (!isNaN(Number(goalId)) && !isNaN(Number(goalStepId))) {
            if (userRole === UserRoleKeys.RoleCorp) {
                const programId = Number(searchParams.get('programId'))

                getSingleProgramGoal(programId, Number(goalId))
                    .then((res) => {
                        setGoalData(res.data);
                    });

                getSingleProgramGoalStep(programId, Number(goalId), Number(goalStepId))
                    .then((res) => {
                        setGoalStepData(res.data)
                    })
            } else {
                getSingleGoal(Number(goalId))
                    .then((res) => {
                        setGoalData(res.data);
                    });

                getSingleGoalStep(Number(goalId), Number(goalStepId))
                    .then((res) => {
                        setGoalStepData(res.data);
                    })
            }
        }
    }, [userRole, goalId, goalStepId]);

    //meta title
    document.title = `${props.title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" items={breadcrumbItems} breadcrumbItem={(goalStepData.title ?? '') + ' Activity'}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={goalStepActivitiesData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search activities..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Activities"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    divClassName=""
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <GoalStepActivityModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateGoalStepActivity} />
                            <GoalStepActivityModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditGoalStepActivity} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this activity? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteGoalStepActivity}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default GoalStepActivitiesPage;
