// src/config/index.ts
import devConfig from './config.dev';
import stagConfig from './config.staging';
import prodConfig from './config.prod';

let config;
switch (process.env.REACT_APP_ENV_SETTING) {
  case 'development':
    config = devConfig;
    break;
  case 'staging':
    config = stagConfig;
    break;
  case 'production':
    config = prodConfig;
    break;
  default:
    config = devConfig; // Default to development config
}

export default config;
