import React, { useEffect, useMemo, useState } from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { updateGuardrail, deleteGuardrail, getGuardrails } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { Link } from "react-router-dom";
import GuardrailModal from "Components/Modal/GuardrailModal";
import DeleteModal from "Components/Modal/DeleteModal";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}

interface ContentGuardrail {
  type: string;
  strength: string;
  description: string;
  response: string;
  escalation_paths: string[];
  escalation_methods: string[];
}

interface TopicGuardrail {
  name: string;
  definition: string;
  examples: string[];
  response: string;
  escalation_paths: string[];
  escalation_methods: string[];
}

const GuardrailPage = (props) => {
    const [guardrailData, setGuardrailData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [guardrailType, setGuardrailType] = useState<string>("contentPolicy");

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }
    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }
    const toggleCreateModal = () => {
      setCreateModalIsOpen(!createModalIsOpen);
      removeBodyCss();
    };

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const guardrailTypeSelector = (
      <Row className="justify-content-end" xs="auto">
          <Col className="btn-group" role="role">
              <input type="radio" className="btn-check" name="role" id="role_corp" autoComplete="off" defaultChecked onClick={() => setGuardrailType("contentPolicy")} />
              <label className="btn btn-outline-secondary" htmlFor="role_corp">Content</label>
    
              <input type="radio" className="btn-check" name="role" id="role_admin" autoComplete="off" onClick={() => setGuardrailType("topicPolicy")} />
              <label className="btn btn-outline-secondary" htmlFor="role_admin">Topics</label>
          </Col>
      </Row>
    );

    const handlePostGuardrail = (guardrail: ContentGuardrail & TopicGuardrail) => {
      (async () => {
          try {
              const { error } = await updateGuardrail(guardrailType, guardrail);
              if (error) {
                  toast.error(error || "Failed to update guardrails.");
                  return;
              }
              setGuardrailData((state) => [guardrail, ...state]);
              toast.success("guardrail  created successfully.");
          } catch (error) {
              toast.error("Failed to update guardrails.");
          }
      })();
    }

    const handleUpdateGuardrail = (guardrail: ContentGuardrail & TopicGuardrail) => {
        (async () => {
            try {
                const { error } = await updateGuardrail(guardrailType, guardrail);
                if (error) {
                    toast.error(error || "Failed to update guardrails.");
                    return;
                }
                setGuardrailData((state) => {
                  const findIdx = state.findIndex(g => guardrailType === "contentPolicy" ? g?.type === guardrail.type : g?.name === guardrail.name);
                  if (!isNaN(findIdx)) {
                    state[findIdx] = guardrail;
                  }
                  return [...state];
                });
                toast.success("Set guardrail successfully.");
            } catch (error) {
                toast.error("Failed to update guardrails.");
            }
        })();
    }

    const handleDeleteGuardrail = (guardrailId) => {
      (async () => {
          try {
              const { error } = await deleteGuardrail(guardrailType, [guardrailId]);
              if (error) {
                  toast.error(error || "Failed to remove guardrail.");
                  return;
              }
              setGuardrailData(state => state.filter(g => guardrailType === "contentPolicy" ? g.type !== guardrailId : g.name !== guardrailId));
              toggleDeleteModal()
              toast.success("Removed guardrail successfully.")
          } catch (error) {
              toast.error("Failed to remove guardrail.");
          }
      })();
  }

    const fetchGuardrails = async () => {
        try {
            const guardrailsResponse = await getGuardrails();
            if (guardrailsResponse.error) {
                toast.error(guardrailsResponse.error || "Failed to get guardrails.");
                return;
            }
            setGuardrailData(guardrailsResponse[guardrailType]);
        } catch (error) {
            toast.error("Failed to get guardrails.");
        }
    };

    useEffect(() => {
      fetchGuardrails();
    }, [guardrailType]);

    const columns: column[] = useMemo(() => {
      const cols: any[] = [];
      if (guardrailType === "contentPolicy") {
        cols.push(...[
          {
            header: 'Type',
            accessorKey: 'type',
            enableColumnFilter: false,
            enableSorting: true,
          },
          {
            header: 'Filter Strength',
            accessorKey: 'strength',
            enableColumnFilter: false,
            enableSorting: true,
          },
          {
            header: 'Description',
            accessorKey: 'description',
            enableColumnFilter: false,
            enableSorting: true,
            cell: (info) => <div style={{ whiteSpace: 'normal' }}>{info.getValue()}</div>
          }
        ]);
      } else {
        cols.push(...[
          {
            header: 'Name',
            accessorKey: 'name',
            enableColumnFilter: false,
            enableSorting: true,
          },
          {
            header: 'Definition',
            accessorKey: 'definition',
            enableColumnFilter: false,
            enableSorting: true,
            cell: (info) => <div style={{ whiteSpace: 'normal' }}>{info.getValue()}</div>
          },
          {
            header: 'Example Phrases',
            accessorKey: 'examples',
            enableColumnFilter: false,
            enableSorting: true,
            cell: (info) => <div style={{ whiteSpace: 'normal' }}>
              <ul>{(info.getValue() ?? []).map((v, i) => (<li key={i}>{v}</li>))}</ul>
            </div>
          },
        ]);
      }

      cols.push(...[
        {
          header: 'Bot Response',
          accessorKey: 'response',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (info) => <div style={{ whiteSpace: 'normal' }}>{info.getValue()}</div>
        },
        {
          header: 'Escalation Paths',
          accessorKey: 'escalation_paths',
          enableColumnFilter: false,
          enableSorting: false,
          cell: (info) =>
            <div style={{ whiteSpace: 'normal' }}>
              <div className="d-flex gap-3">
                <p>Manager</p>
                {
                  info.getValue()?.includes("manager") ?
                    <i className="mdi mdi-check font-size-18" id="editTooltip" /> :
                    <i className="mdi mdi-cancel font-size-18" id="editTooltip" />
                }
              </div>
              <div className="d-flex gap-3">
                <p>Admins</p>
                {
                  info.getValue()?.includes("admins") ?
                    <i className="mdi mdi-check font-size-18" id="editTooltip" /> :
                    <i className="mdi mdi-cancel font-size-18" id="editTooltip" />
                }
              </div>
            </div>
        },
        {
          header: 'Escalation Methods',
          accessorKey: 'escalation_methods',
          enableColumnFilter: false,
          enableSorting: false,
          cell: (info) =>
            <div style={{ whiteSpace: 'normal' }}>
              <div className="d-flex gap-3">
                <p>Email</p>
                {
                  info.getValue()?.includes("email") ?
                    <i className="mdi mdi-check font-size-18" id="editTooltip" /> :
                    <i className="mdi mdi-cancel font-size-18" id="editTooltip" />
                }
              </div>
              <div className="d-flex gap-3">
                <p>Text</p>
                {
                  info.getValue()?.includes("text") ?
                    <i className="mdi mdi-check font-size-18" id="editTooltip" /> :
                    <i className="mdi mdi-cancel font-size-18" id="editTooltip" />
                }
              </div>
            </div>
        },
        {
          header: 'Action',
          accessorKey: 'action',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => {
              return (
                  <div className="d-flex gap-3">
                      <Link to="#" className="text-success" onClick={() => {
                          setValueToBeModified(cellProps.row.original)
                          toggleEditModal()
                      }}>
                      <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                      <UncontrolledTooltip placement="top" target="editTooltip">
                          Edit
                      </UncontrolledTooltip>
                      </Link>
                      {guardrailType === "topicPolicy" &&
                        <Link to="#" className="text-danger" onClick={() => {
                          setValueToBeModified(cellProps.row.original)
                          toggleDeleteModal()
                        }}>
                        <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                        <UncontrolledTooltip placement="top" target="deleteTooltip">
                            Delete
                        </UncontrolledTooltip>
                        </Link>}
                  </div>
              )
          }
        }
      ]);

      return cols;
    }, [guardrailType]);

    //meta title
    document.title = `${props.title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={props.title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={guardrailData || []}
                                                    isGlobalFilter={false}
                                                    isPagination={true}
                                                    isAddButton={guardrailType === "topicPolicy"}
                                                    handleUserClick={toggleCreateModal}
                                                    isCustomPageSize={false}
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName="Create New Guardrail"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    divClassName=""
                                                    customLeftTools={guardrailTypeSelector}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <GuardrailModal guardrailType={guardrailType} isOpen={createModalIsOpen} data={valueToBeModified} toggle={toggleCreateModal} onSave={handlePostGuardrail} />
                            <GuardrailModal guardrailType={guardrailType} isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleUpdateGuardrail} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this guardrail? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteGuardrail}
                                deleteProps={guardrailType === "contentPolicy" ? valueToBeModified?.type : valueToBeModified?.name}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default GuardrailPage;
