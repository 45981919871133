import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from 'reactstrap';
import {OriginalEvent} from "./GoalInterface";
import FileIcon from "./FileIcon"; // Adjust based on your UI library




// Props type if originalEvent is passed as a prop
interface ModalProps {
    originalEvent: OriginalEvent;
    isOpen: boolean;
    toggle: () => void;
}

const ViewGoalModal: React.FC<ModalProps> = ({ originalEvent, isOpen, toggle }) => {
    // Function to extract filename from a URL
    const extractFilename = (url) => {
        if (!url) return '';
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    };

    const evidenceFilename = extractFilename(originalEvent.evidenceUri);

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>
                Completed Goal
            </ModalHeader>
            <ModalBody className="p-4">
                <div className="mb-3">
                    <Label>Goal</Label>
                    <p>{originalEvent.goal.title}</p>
                </div>
                <div className="mb-3">
                    <Label>Goal Step</Label>
                    <p>{originalEvent.goalStep.title}</p>
                </div>
                <div className="mb-3">
                    <Label>Goal Step Activity</Label>
                    <p>{originalEvent.goalStepActivity.title}</p>
                </div>
                <div className="mb-3">
                    <Label>Scheduled Date and Time</Label>
                    <p>{originalEvent.scheduledDatetime}</p>
                </div>
                {/* Include other details as necessary */}
                {originalEvent.evidenceUri && (
                    <div className="mb-3">
                        <Label>Evidence</Label>
                        <div>
                            <FileIcon filename={evidenceFilename} /> {/* Display the file icon */}
                            <a href={originalEvent.evidenceUri} target="_blank" rel="noopener noreferrer">
                                {evidenceFilename} {/* Display the filename and make it clickable */}
                            </a>
                        </div>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ViewGoalModal;
