import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";

import Breadcrumbs from 'Components/Common/Breadcrumb';
import SendNotificationForm from "Components/Form/SendNotificationForm";
import { getNotificationChannels, sendNotification } from "helpers/backend_helper";

const SendNotificationPage = ({ title }) => {
  const [channels, setChannels] = useState<string[]>([]);
  //meta title
  document.title = `${title} | Eckerd Connects - Notifications`;

  useEffect(() => {
    const fetchChannels = async () => {
      try {
          const response = await getNotificationChannels(1); // Add parameters if needed
          if (response.success) {

              setChannels(response.data.channels);
          } else {
              // Display error notification
              toast.error(response.error || "Failed to fetch channels.");
          }
      } catch (error) {

          toast.error("Failed to fetch channels.");
      }
  };
  fetchChannels();
  }, []);
  
  const handleSendNotification = (newNotification: any) => {
    const sendNotificationApi = async () => {
        try {
            const response = await sendNotification(newNotification); // Add parameters if needed
            if (response.success) {
                toast.success("Notification sent.");
            } else {
                // Display error notification
                toast.error(response.error || "Failed to send notification.");
            }
        } catch (error) {

            toast.error("Failed to send notification.");
        }
    };

    sendNotificationApi();
  }

  return (
    <div className="page-content">
      <ToastContainer />
      <Container fluid>
        <Breadcrumbs title="List" breadcrumbItem={title} />
        <Row>
          <Col lg={12}>
            <SendNotificationForm channels={channels} onSend={handleSendNotification} />
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default SendNotificationPage;