import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addGoalStep, addProgramGoalStep, deleteGoalStep, deleteProgramGoalStep, getGoalSteps, getProgramGoalSteps, getSingleGoal, getSingleProgramGoal, updateGoalStep, updateProgramGoalStep } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import GoalStepModal from "Components/Modal/GoalStepModal";
import { convertUTC2Local } from "utils";
import { UserRoleKeys, userManager } from "utils/UserManager";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const GoalStepsPage = (props) => {
    const [managersData, setManagersData] = useState([]);
    const params = useParams();
    const userRole = userManager.getUserRole();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [goalStepsData, setGoalStepsData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [goalData, setGoalData] = useState<any>({});
    const { goalId } = params;

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        if (!isNaN(Number(goalId))) {
            if (userRole === UserRoleKeys.RoleCorp) {
                const programId = Number(searchParams.get('programId'))

                getSingleProgramGoal(programId, Number(goalId))
                    .then((res) => {
                        setGoalData(res.data);
                    });
            } else {
                getSingleGoal(Number(goalId))
                    .then((res) => {
                        setGoalData(res.data);
                    });
            }
        }
    }, [userRole, goalId]);

    const handleCreateGoalStep = (newGoalStep: any) => {
        const programId = Number(searchParams.get('programId'))

        const createGoalStep = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp
                    ? await addProgramGoalStep(programId, Number(goalId), newGoalStep)
                    : await addGoalStep(Number(goalId), newGoalStep); // Add parameters if needed
                if (response.success) {

                    setGoalStepsData((state) => [...state, response.data]);
                    toast.success("Created new goal step.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create goal step.");
                }
            } catch (error) {

                toast.error("Failed to create goal step.");
            }
        };

        createGoalStep();
    }

    const handleEditGoalStep = (goalStep: any) => {
        const programId = Number(searchParams.get('programId'))

        const modifyGoalStep = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp
                    ? await updateProgramGoalStep(programId, Number(goalId), goalStep)
                    : await updateGoalStep(Number(goalId), goalStep); // Add parameters if needed
                if (response.success) {

                    setGoalStepsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === goalStep.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = goalStep
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update goal step.");
                }
            } catch (error) {

                toast.error("Failed to update goal step.");
            }
        };

        modifyGoalStep();
    }

    const handleDeleteGoalStep = () => {
        const programId = Number(searchParams.get('programId'))

        const removeGoalStep = async () => {
            try {
                const response = userRole === UserRoleKeys.RoleCorp
                    ? await deleteProgramGoalStep(programId, Number(goalId), valueToBeModified.id)
                    : await deleteGoalStep(Number(goalId), valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setGoalStepsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove goal step.");
                }
            } catch (error) {

                toast.error("Failed to remove goal step.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeGoalStep()
        }
    }

    useEffect(() => {
        const programId = Number(searchParams.get('programId'))

        if (userRole === UserRoleKeys.RoleCorp && !isNaN(programId)) {
            const fetchGoalSteps = async () => {
                try {
                    const response = await getProgramGoalSteps(programId, Number(goalId), 1); // Add parameters if needed
                    if (response.success) {

                        setGoalStepsData(response.data.goalSteps);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch goal steps.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch goal steps.");
                }
            };
            if (!isNaN(Number(goalId))) {
                fetchGoalSteps();
            }
        } else if (userRole === UserRoleKeys.RoleAdmin) {
            const fetchGoalSteps = async () => {
                try {
                    const response = await getGoalSteps(Number(goalId), 1); // Add parameters if needed
                    if (response.success) {

                        setGoalStepsData(response.data.goalSteps);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch goal steps.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch goal steps.");
                }
            };
            if (!isNaN(Number(goalId))) {
                fetchGoalSteps();
            }
        }
    }, [goalId, searchParams, userRole]);

    const breadcrumbItems: any[] = useMemo(
      () => [
        {
          link: '/goals',
          title: 'Goals'
        }
      ],
      []
    );

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Title',
                accessorKey: 'title',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const goalStepId = cellProps.row.original.id
                    const programId = Number(searchParams.get('programId'))
                    const viewUrl = userRole === UserRoleKeys.RoleCorp ? `/goals/${goalId}/goal-steps/${goalStepId}?programId=${programId}` : `/goals/${goalId}/goal-steps/${goalStepId}`
                    return (
                        <div className="d-flex gap-3">
                            <button className="btn text-success p-0" onClick={() => {
                                setValueToBeModified(cellProps.row.original);
                                toggleEditModal();
                            }}>
                                <i className="mdi mdi-pencil font-size-18" id="editTooltip"/>
                                <UncontrolledTooltip placement="top" target="editTooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </button>
                            <Link to={viewUrl} className="text-primary">
                                <i className="mdi mdi-eye font-size-18" id="viewTooltip"/>
                                <UncontrolledTooltip placement="top" target="viewTooltip">
                                    View
                                </UncontrolledTooltip>
                            </Link>
                            <button className="btn text-danger p-0" onClick={() => {
                                setValueToBeModified(cellProps.row.original);
                                toggleDeleteModal();
                            }}>
                                <i className="mdi mdi-delete font-size-18" id="deleteTooltip"/>
                                <UncontrolledTooltip placement="top" target="deleteTooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </button>

                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal, searchParams, userRole]
    );

    //meta title
    document.title = `${props.title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" items={breadcrumbItems} breadcrumbItem={(goalData?.title ?? '') + ' Step'}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={goalStepsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search goal steps..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Goal Step"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    divClassName=""
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <GoalStepModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateGoalStep} />
                            <GoalStepModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditGoalStep} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this goal step? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteGoalStep}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default GoalStepsPage;
