import { FaFileImage, FaFilePdf, FaFileAlt, FaFile } from 'react-icons/fa'; // Import specific icons

const FileIcon = ({ filename }) => {
    const extension = filename.split('.').pop();

    switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return <FaFileImage />;
        case 'pdf':
            return <FaFilePdf />;
        case 'txt':
            return <FaFileAlt />;
        default:
            return <FaFile />; // Default file icon
    }
};

export default FileIcon;
