import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FileUploader from 'Components/Common/FileUploader'; // Importing FileUploader component
import { generalizeValue } from 'utils';

type ILocationModal = {
    data?: any;
    programs?: any[];
    isOpen: boolean;
    isEdit?: boolean;
    onSave?: Function;
    toggle: () => void;
}

const LocationModal: React.FC<ILocationModal> = ({ data, isOpen, programs = [], isEdit, onSave, toggle }) => {
    const [newData, setNewData] = useState<any>({});
    const [programId, setProgramId] = useState<number>();
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoPreview, setLogoPreview] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const handleSaveClick = async () => {
        setLoading(true);
        console.log('loading');
        try {
            await handleSave(); // Ensure handleSave is async

        } catch (error) {
            console.error('Failed to save location:', error);
        } finally {
            setLoading(false);
            console.log('end loading');
        }
    };
    const handleSave = async () => {
        const saveData = { ...newData };

        if (logoFile) {
            saveData.logoFile = logoFile; // Only include logoFile if a file is selected
        }

        if (onSave) {
            await onSave(isEdit ? newData.programId : programId, saveData);
        }
    };

    const handleFieldInput = (e) => {
        const generalizedValue = generalizeValue(e.target.type, e.target.value);
        setNewData((state) => ({
            ...state,
            [e.target.id]: generalizedValue,
        }));
    };

    useEffect(() => {
        if (isOpen) {
            if (isEdit) {
                setNewData({ ...data });
                setLogoPreview(data?.settings?.logo || null);  // Check if logo exists
                setLogoFile(null);  // Reset logoFile when modal opens
            } else {
                setNewData({});
                setLogoPreview(null);  // Reset logoPreview when modal opens
                setLogoFile(null);  // Reset logoFile when modal opens
            }
        }
    }, [data, isEdit, isOpen]);

    useEffect(() => {
        if (programs.length) {
            setProgramId(programs[0].id);
        }
    }, [programs]);

    const handleUploadLocationFiles = (files: File[]) => {
        if (files.length > 0) {
            setLogoFile(files[0]); // Set the selected file as logoFile
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    {isEdit ? 'Update Location' : 'New Location'}
                </div>
            </ModalHeader>
            <ModalBody>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input value={newData?.name} type="text" className="form-control" id="name" placeholder="Enter name" onChange={handleFieldInput} />
                    </div>
                    {!isEdit && (
                        <div className="mb-3">
                            <label htmlFor="programId" className="form-label">Program</label>
                            <select className="form-select form-control" value={programId} onChange={(e) => {
                                setProgramId(Number(e.target.value));
                            }}>
                                {programs?.map(program => (
                                    <option key={program.id} value={program.id}>{program.name}</option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Other settings and form fields */}
                    <div className="form-check mb-3">
                        <input
                            checked={newData?.settings?.case_manager_invite ?? false}
                            className="form-check-input"
                            type="checkbox"
                            id="case_manager_invite"
                            onChange={() => {
                                setNewData((state) => ({
                                    ...state,
                                    "settings": {
                                        ...(state?.settings ?? {}),
                                        "case_manager_invite": !state?.settings?.case_manager_invite,
                                    },
                                }));
                            }}
                        />
                        <label className="form-check-label" htmlFor="case_manager_invite">
                            Case Managers Can Send Client Invites
                        </label>
                    </div>
                    <div className="form-check mb-3">
                        <input
                            checked={newData?.settings?.case_manager_push ?? false}
                            className="form-check-input"
                            type="checkbox"
                            id="case_manager_push"
                            onChange={() => {
                                setNewData((state) => ({
                                    ...state,
                                    "settings": {
                                        ...(state?.settings ?? {}),
                                        "case_manager_push": !state?.settings?.case_manager_push,
                                    },
                                }));
                            }}
                        />
                        <label className="form-check-label" htmlFor="case_manager_push">
                            Enable Push Notification for Case Managers
                        </label>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="use_coach_name" className="form-label">Coach/Check-in section title (ex. Coach, Manager, etc.)</label>
                        <input value={newData?.settings?.use_coach_name} type="text" className="form-control" id="use_coach_name" placeholder="Enter coach name"
                               onChange={(e) => {
                                   setNewData((state) => ({
                                       ...state,
                                       "settings": {
                                           ...(state?.settings),
                                           "use_coach_name": e.target.value,
                                       },
                                   }));
                               }}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="client_title" className="form-label">Title for Client (ex. student, graduate, etc.)</label>
                        <input value={newData?.settings?.client_title} type="text" className="form-control" id="client_title" placeholder="Enter title for client"
                               onChange={(e) => {
                                   setNewData((state) => ({
                                       ...state,
                                       "settings": {
                                           ...(state?.settings),
                                           "client_title": e.target.value,
                                       },
                                   }));
                               }}
                        />
                    </div>
                    <div className="form-check mb-3">
                        <input
                            checked={newData?.settings?.chatbot_enabled ?? false}
                            className="form-check-input"
                            type="checkbox"
                            id="chatbot_enabled"
                            onChange={() => {
                                setNewData((state) => ({
                                    ...state,
                                    "settings": {
                                        ...(state?.settings ?? {}),
                                        "chatbot_enabled": !state?.settings?.chatbot_enabled,
                                    },
                                }));
                            }}
                        />
                        <label className="form-check-label" htmlFor="chatbot_enabled">
                            Enable Chatbot for Location
                        </label>
                    </div>

                        <>
                            {logoPreview && (
                                <div className="mb-3">
                                    <label>Current Logo</label>
                                    <div className="image-preview mt-3">
                                        <img src={logoPreview} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                    </div>
                                </div>
                            )}

                            {/* Logo Upload Section */}
                            <FileUploader
                                label="Upload Logo File"
                                hint="Please upload a logo file related to the location."
                                multiple={false}
                                onSend={handleUploadLocationFiles}
                                onChange={handleUploadLocationFiles}
                            />
                        </>


                    <p>Please fill out the form to create a new location.</p>
                </form>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveClick}
                    disabled={loading} // Disable the button while loading
                >
                    {loading ? 'Saving...' : isEdit ? 'Update location' : 'Create location'}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default LocationModal;
