import axios from "axios";
import { BASE_URL } from "./url_helper";
import {userManager} from "../utils/UserManager";
import {getCognitoBackend} from "@helpers/cognito_helper";
console.log(BASE_URL);
// Set up default configuration
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Axios response interceptor for error handling
axios.interceptors.response.use(
  response => response.data ? response.data : response,
  async error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {

      try {
        // Attempt to refresh the JWT token
        const newToken = await userManager.refreshCognitoSession();

        console.log(newToken);
        // Update the authorization header globally, if necessary
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        // Update the token on the original request
        error.config.headers['Authorization'] = `Bearer ${newToken}`;

        // Retry the request with the new token
        return axios(error.config);
      } catch (refreshError) {
        // If the refresh fails, you might want to remove invalid tokens
        // from storage and require the user to log in again
       userManager.logoutUser();
        return Promise.reject(new Error("Session expired, please log in again."));
      }
    }else if (error.code === 'ERR_NETWORK') {


      try {
        // Attempt to refresh the JWT token
        const newToken = await userManager.refreshCognitoSession();

        console.log(newToken);
        // Update the authorization header globally, if necessary
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        // Update the token on the original request
        error.config.headers['Authorization'] = `Bearer ${newToken}`;

        // Retry the request with the new token
        return axios(error.config);
      } catch (refreshError) {
        // If the refresh fails, you might want to remove invalid tokens
        // from storage and require the user to log in again
        userManager.logoutUser();
        return Promise.reject(new Error("Network Error - Please check your network connection."));
      }
    }  else {
      // Handle other errors
      return Promise.reject(new Error(error.response?.data?.error || error.message));
    }
  }
);

// Function to set the default authorization header
const setAuthorization = (token: string | null) => {
  if (token) {
    const formattedToken = token.replace(/^"|"$/g, '');
    axios.defaults.headers.common["Authorization"] = `Bearer ${formattedToken}`;
} else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

// Function to set the x-api-key
const setXApiKey = (apiKey: string | null) => {
  if (apiKey) {
    axios.defaults.headers.common["x-api-key"] = apiKey
  } else {
    delete axios.defaults.headers.common["x-api-key"]
  }
}

// Function to get the logged-in user from localStorage
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  return user ? JSON.parse(user) : null;
};

class APIClient {
  // GET request method
  get(url: string, params?: Record<string, any>) {
    const queryString = params
        ? Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')
        : '';
    return axios.get(`${url}${queryString ? `?${queryString}` : ''}`);
  }

  // POST request method
  create(url: string, data: Record<string, any>) {
    return axios.post(url, data);
  }

  // PATCH request method for updating data
  update(url: string, data: Record<string, any>) {
    return axios.patch(url, data);
  }

  // PUT request method
  put(url: string, data: Record<string, any>) {
    return axios.put(url, data);
  }

  // DELETE request method
  delete(url: string, config?: Record<string, any>) {
    return axios.delete(url, config);
  }
}

export { APIClient, setAuthorization, setXApiKey };
