import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { getNotifications } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import GoalModal from "Components/Modal/GoalModal";
import { convertUTC2Local } from "utils";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const NotificationsPage = ({title}) => {
    const navigate = useNavigate();
    const [notificationsData, setNotificationsData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await getNotifications(1); // Add parameters if needed
                if (response.success) {

                    setNotificationsData(response.data.notifications);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch notifications.");
                }
            } catch (error) {

                toast.error("Failed to fetch notifications.");
            }
        };
        fetchNotifications();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Title',
                accessorKey: 'title',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Message',
                accessorKey: 'message',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Channels',
                accessorKey: 'channels',
                cell: (info) => info.getValue().map(item => item['displayName']).join(", "), 
                enableColumnFilter: false,
                enableSorting: true,
            },
            // Add more columns as needed
        ],
        [toggleNewModal]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Notifications`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={notificationsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    SearchPlaceholder="Search notifications..."
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default NotificationsPage;
