import React, { useEffect, useMemo, useState } from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { getKnowledgeBases, createKnowledgeBase, updateKnowledgeBase, deleteKnowledgeBase, uploadFiles, uploadImageOnS3 } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import { Link } from "react-router-dom";
import KnowledgeBaseModal from "Components/Modal/KnowledgeBaseModal";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}

interface NewKnowledgeBase {
  name: string;
  description?: string;
  mediaUri?: string
}
interface KnowledgeBase extends NewKnowledgeBase {
  id: number;
}


const KnowledgeBasePage = (props) => {
    const [knowledgeBasesData, setKnowledgeBasesData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateKnowledgeBase = (newKnowledgeBase: NewKnowledgeBase, files: File[] = []) => {
        (async () => {
            try {

                const { data: knowledgeBase, error } = await createKnowledgeBase(newKnowledgeBase);
                if (error) {
                    toast.error(error || "Failed to create knowledge base.");
                    return;
                }

                setKnowledgeBasesData((state) => [knowledgeBase, ...state]);
                if (files.length > 0) {
                  await handleFileUpload(knowledgeBase.id, files)
                }

                toast.success("Created new knowledge base.");
                toggleNewModal();
            } catch (error) {
                toast.error("Failed to create knowledge base.");
            }
        })();
    }

    const handleEditKnowledgeBase = (knowledgeBase: KnowledgeBase, files: File[]) => {
        (async () => {
            try {
                const { error } = await updateKnowledgeBase(knowledgeBase.id, knowledgeBase);
                if (error) {
                    toast.error(error || "Failed to update knowledge base.");
                    return;
                }

                setKnowledgeBasesData((state) => {
                    const findIdx = state.findIndex(kb => kb?.id === knowledgeBase.id);
                    console.log(JSON.stringify({findIdx}, null, 4))
                    if (!isNaN(findIdx)) {
                        state[findIdx] = knowledgeBase
                    }

                    return [...state];
                });
                toggleEditModal()

                if (files.length > 0) {
                  await handleFileUpload(knowledgeBase.id, files)
                }
            } catch (error) {
                toast.error("Failed to update knowledge base.");
            }
        })();
    }

    const handleDeleteKnowledgeBase = (knowledgeBaseId: number) => {
        (async () => {
            try {
                const { error } = await deleteKnowledgeBase(knowledgeBaseId);
                if (error) {
                    toast.error(error || "Failed to remove knowledge base.");
                    return;
                }

                setKnowledgeBasesData((state) => state.filter(kb => kb?.id !== knowledgeBaseId));
                toggleDeleteModal()
            } catch (error) {
                toast.error("Failed to remove knowledge base.");
            }
        })();
    }

    const fetchKnowledgeBases = async () => {
        try {
            const { data, error } = await getKnowledgeBases(); // Add parameters if needed
            if (error) {
                toast.error(error || "Failed to fetch knowledge bases.");
                return;
            }

            setKnowledgeBasesData(data.knowledgeBases);
        } catch (error) {
            toast.error("Failed to fetch knowledge bases.");
        }
    };

    useEffect(() => {
        fetchKnowledgeBases();
    }, []);

    const handleFileUpload = async (knowledgeBaseId: number, files: File[]) => {
        if (files.length > 0) {
            const uploads = [
                {
                  resourcePath: `resources/knowledge-bases/${knowledgeBaseId}`,
                    fileName: files[0]['name']
                }
            ]

            const { data, error } = await uploadFiles(uploads);

            if (error) {
                toast.error(error || "Failed to upload file.");
                return;
            }

            try {
              const { putUrl } = data.uploads[0]; 
              uploadImageOnS3(putUrl, files[0])
                .then(() => {
                    fetchKnowledgeBases();
                });
            } catch (err) {
                console.error(err);
            }
        }
    }

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Description',
                accessorKey: 'description',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                            {cellProps.row.original?.mediaUri && <Link to="#" className="blue" onClick={() => {
                              window.open(cellProps.row.original.mediaUri, '_blank')
                            }}>
                                <i className="mdi mdi-download font-size-18" id="downloadTooltip" />
                                <UncontrolledTooltip placement="top" target="downloadTooltip">
                                    Download
                                </UncontrolledTooltip>
                            </Link>}
                        </div>
                    )
                }
            },
        ],
        [toggleNewModal]
    );

    //meta title
    document.title = `${props.title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={props.title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={knowledgeBasesData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search Knowledge Base..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Knowledge Base"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    divClassName=""
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <KnowledgeBaseModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateKnowledgeBase} onUploadFile={handleFileUpload} />
                            <KnowledgeBaseModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditKnowledgeBase} onUploadFile={handleFileUpload} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this knowledge base? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteKnowledgeBase}
                                deleteProps={valueToBeModified?.id}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default KnowledgeBasePage;
