import React from "react";
import {mapRoleToEnum, UserRoleKeys} from "utils/UserManager";

type RoleCellProps = {
    value: string;
  };

  const RoleCell: React.FC<RoleCellProps> = ({ value }) => {
    let badgeColor;

    console.log(value);
    switch (value) {
      case UserRoleKeys.RoleCorp:
        badgeColor = 'primary';
        break;
      case UserRoleKeys.RoleAdmin:
        badgeColor = 'success';
        break;
      case UserRoleKeys.RoleManager:
        badgeColor = 'info';
        break;
      // Define more roles and colors as needed
      default:
        badgeColor = 'danger';
    }
    console.log('Rendering RoleCell with value:', value);
    return  <span className={`badge-soft-${badgeColor} ms-1 badge bg-${badgeColor} rounded-pill`}>
    {mapRoleToEnum(value)}
  </span>;
  };

export default React.memo(RoleCell);
