import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addRule, assignGroupRule, deleteRule, getRuleGroups, getRules, removeGroupRule, updateRule} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import RuleModal from "Components/Modal/RuleModal";
import RuleGroupAssignModal from "Components/Modal/RuleGroupAsssignModal";
import { convertUTC2Local } from "utils";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const RulesPage = ({title}) => {
    const [managersData, setManagersData] = useState([]);
    const [rulesData, setRulesData] = useState<any[]>([]);
    const [ruleGroupsData, setRuleGroupsData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [groupModalIsOpen, setGroupModalIsOpen] = useState<boolean>(false);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(state => !state);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(state => !state);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(state => !state);
        removeBodyCss();
    }

    function toggleGroupModal() {
        setGroupModalIsOpen((state) => !state);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleSaveGroupChanges = () => {

    }

    const handleCreateRule = (newRule: any) => {
        const createRule = async () => {
            try {
                const response = await addRule({
                    ...newRule,
                }); // Add parameters if needed
                if (response.success) {

                    setRulesData((state) => [...state, {
                        ...response.data,
                        ruleGroups: [],
                        ruleGroupIds: []
                    }]);
                    toast.success("Created new rule.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create rule.");
                }
            } catch (error) {

                toast.error("Failed to create rule.");
            }
        };

        createRule();
    }

    const handleEditRule = (rule: any) => {
        const modifyRule = async () => {
            try {
                const response = await updateRule(rule); // Add parameters if needed
                if (response.success) {

                    setRulesData((state) => {
                        const findIdx = state.findIndex(item => item?.id === rule.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = rule
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update rule.");
                }
            } catch (error) {

                toast.error("Failed to update rule.");
            }
        };

        modifyRule();
    }

    const handleDeleteRule = () => {
        const removeRule = async () => {
            try {
                const response = await deleteRule(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setRulesData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove rule.");
                }
            } catch (error) {

                toast.error("Failed to remove rule.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeRule()
        }
    }

    const handleAttachGroup = (groupId: number) => {
        const assignRuleToGroup = async () => {
            try {
                const ruleId = valueToBeModified.id;
                const response = await assignGroupRule(ruleId, groupId); // Add parameters if needed
                if (response.success) {

                    setRulesData((state) => {
                        const ruleIdx = state.findIndex(item => item.id === ruleId);
                        state[ruleIdx] = {
                            ...state[ruleIdx],
                            ruleGroups: [
                                ...(state[ruleIdx]?.ruleGroups || []),
                                ruleGroupsData.find(item => item.id === groupId)
                            ],
                            ruleGroupIds: [
                                ...(state[ruleIdx]?.ruleGroupIds || []),
                                ruleGroupsData.find(item => item.id === groupId)?.id
                            ]
                        }
                        return JSON.parse(JSON.stringify(state))
                    });
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to assign a rule to group.");
                }
            } catch (error) {

                toast.error("Failed to assign a rule to group.");
            }
        }

        assignRuleToGroup();
    }

    const handleDetachGroup = (groupId: number) => {
        const detachRuleFromGroup = async () => {
            try {
                const ruleId = valueToBeModified.id;
                const response = await removeGroupRule(ruleId, groupId); // Add parameters if needed
                if (response.success) {

                    setRulesData((state) => {
                        const ruleIdx = state.findIndex(item => item.id === ruleId);
                        state[ruleIdx] = {
                            ...state[ruleIdx],
                            ruleGroups: [
                                ...state[ruleIdx]?.ruleGroups?.filter(item => item.id !== groupId)
                            ],
                            ruleGroupIds: [
                                ...state[ruleIdx]?.ruleGroupIds?.filter(item => item !== groupId),
                            ]
                        }
                        return JSON.parse(JSON.stringify(state))
                    });
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to assign a rule to group.");
                }
            } catch (error) {

                toast.error("Failed to assign a rule to group.");
            }
        }

        detachRuleFromGroup();
    }

    useEffect(() => {
        const fetchRules = async () => {
            try {
                const response = await getRules(1); // Add parameters if needed
                if (response.success) {

                    setRulesData(response.data.rules);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch rules.");
                }
            } catch (error) {

                toast.error("Failed to fetch rules.");
            }
        };
        const fetchRuleGroups = async () => {
            try {
                const response = await getRuleGroups(1); // Add parameters if needed
                if (response.success) {

                    setRuleGroupsData(response.data.ruleGroups);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch rule groups.");
                }
            } catch (error) {

                toast.error("Failed to fetch rule groups.");
            }
        };
        // fetchManagers();
        fetchRules();
        fetchRuleGroups();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Event',
                accessorKey: 'event',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Groups',
                accessorKey: 'ruleGroups',
                cell: (info) => (info.getValue() ?? []).map(item => item?.name).join(' | ') || 'No group',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Points',
                accessorKey: 'points',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Max Usage (7 Days)',
                accessorKey: 'maxUsage7Days',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Max Points (7 Days)',
                accessorKey: 'maxPoints7Days',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Max Usage (45 Days)',
                accessorKey: 'maxUsage45Days',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Max Points (45 Days)',
                accessorKey: 'maxPoints45Days',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={rulesData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search rules..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Rule"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <RuleModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateRule} />
                            <RuleModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditRule} onManageGroup={toggleGroupModal} />
                            <RuleGroupAssignModal
                                attachedGroups={rulesData?.find(item => item.id === valueToBeModified?.id)?.ruleGroups}
                                ruleGroups={ruleGroupsData}
                                isOpen={groupModalIsOpen}
                                toggle={toggleGroupModal}
                                onSave={handleSaveGroupChanges}
                                onAttach={handleAttachGroup}
                                onDetach={handleDetachGroup}
                            />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this rule? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteRule}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default RulesPage;
