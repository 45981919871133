import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    ICognitoUserPoolData,
    ICognitoUserData, CognitoRefreshToken,
} from 'amazon-cognito-identity-js';


class CognitoAuthBackend {
    userPool: CognitoUserPool;

    constructor(cognitoConfig: ICognitoUserPoolData) {
        this.userPool = new CognitoUserPool(cognitoConfig);
    }

    registerUser = (email: string, password: string) => {
        return new Promise((resolve, reject) => {
            const attributeList = [
                new CognitoUserAttribute({ Name: 'email', Value: email }),
            ];

            this.userPool.signUp(email, password, attributeList, [], (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(result?.user);
            });
        });
    };

    loginUser = (username: string, password: string): Promise<CognitoUserSession> => {
        return new Promise((resolve, reject) => {
            const authenticationDetails = new AuthenticationDetails({
                Username: username,
                Password: password,
            });

            const userData: ICognitoUserData = {
                Username: username,
                Pool: this.userPool,
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session: CognitoUserSession) => {
                    resolve(session);
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    };
    forgotPassword = (email: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const userData: ICognitoUserData = {
                Username: email,
                Pool: this.userPool,
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.forgotPassword({
                onSuccess: () => {
                    resolve(); // Resolve without value, indicating success
                },
                onFailure: (err) => {
                    console.error('ForgotPassword Error:', err); // Logging for internal debugging
                    reject(new Error("Failed to change password."));
                },
            })
        });
    };
    changePassword = (email: string, confirmationCode: string, password: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const userData: ICognitoUserData = {
                Username: email,
                Pool: this.userPool,
            };

            const cognitoUser = new CognitoUser(userData);

            cognitoUser.confirmPassword(confirmationCode, password, {
                onSuccess: () => {
                    resolve();  // Resolve without value, indicating success
                },
                onFailure: (err) => {
                    console.error('ConfirmPassword Error:', err); // Logging for internal debugging
                    reject(new Error("Failed to change password."));
                },
            });
        });
    };

    refreshSession = (refreshTokenString: string): Promise<CognitoUserSession> => {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.userPool.getCurrentUser();
            if (!cognitoUser) {
                reject(new Error("No current user"));
                return;
            }

            // Ensure the user data is loaded from local storage
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                    return;
                }

                // Now refresh the session using the provided refresh token
                const refreshToken = new CognitoRefreshToken({ RefreshToken: refreshTokenString });

                cognitoUser.refreshSession(refreshToken, (err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            });
        });
    };
    logout = () => {
        const cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
        }
    };

    // Additional methods like password reset, user confirmation, etc. can be added here.

    _handleError = (error: any) => {
        return error.message || JSON.stringify(error);
    };
}

// Initialization and export functions
let _cognitoAuthBackend: CognitoAuthBackend | null = null;

const initCognitoBackend = (config: ICognitoUserPoolData) => {
    if (!_cognitoAuthBackend) {
        _cognitoAuthBackend = new CognitoAuthBackend(config);
    }
    return _cognitoAuthBackend;
};

const getCognitoBackend = () => {
    return _cognitoAuthBackend;
};

export { initCognitoBackend, getCognitoBackend };
