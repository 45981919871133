import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {addResourceCategory, deleteResourceCategory, getResourceCategories, updateResourceCategory} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import ResourceCategoryModal from "Components/Modal/ResourceCategoryModal";
import { convertUTC2Local } from "utils";
import {userManager} from "../../../utils/UserManager";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}

const currentUser = userManager;
const ResourceCategoriesPage = ({title}) => {
    const [managersData, setManagersData] = useState([]);
    const navigate = useNavigate();
    const [categories, setCategoriesData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateCategory = (newCategory: any) => {
        const createCategory = async () => {
            try {
                const response = await addResourceCategory(newCategory); // Add parameters if needed
                if (response.success) {

                    setCategoriesData((state) => [...state, response.data]);
                    toast.success("Created new category.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create category.");
                }
            } catch (error) {

                toast.error("Failed to create category.");
            }
        };

        createCategory();
    }
// Function to remove unnecessary fields from the category data
    function prepareCategoryData(categoryData) {
        const { id, createdAt, updatedAt, deletedAt, lastModifiedBy, ...cleanData } = categoryData;
        return cleanData;
    }
    const handleEditCategory = (originalCategoryData: any) => {
        const modifyCategory = async () => {
            try {
                // Preparing the category data for API request
                const category = prepareCategoryData(originalCategoryData);
                const response = await updateResourceCategory(category,originalCategoryData.id); // Add parameters if needed
                if (response.success) {

                    setCategoriesData((state) => {
                        const findIdx = state.findIndex(item => item?.id === originalCategoryData.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = originalCategoryData
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update category.");
                }
            } catch (error) {

                toast.error("Failed to update category.");
            }
        };

        modifyCategory();
    }

    const handleDeleteCategory = () => {
        const removeCategory = async () => {
            try {
                const response = await deleteResourceCategory(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setCategoriesData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove category.");
                }
            } catch (error) {

                toast.error("Failed to remove category.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeCategory()
        }
    }

    useEffect(() => {
        const fetchResourceCategories = async () => {
            try {
                // Fetch the first set of resource categories
                const response = await getResourceCategories(1, 0, 50);

                // Initialize an empty array for concatenated categories
                let allCategories: any[] = [];

                // Check the success of the first response
                if (response.success) {
                    // Attach the 'type' field to each item in the first set
                    const updatedCategories = response.data.resourceCategories.map((category: any) => ({
                        ...category,
                        type: 'tenant' // Assign 'tenant' type to the first set of categories
                    }));
                    allCategories = [...updatedCategories]; // Add first set to the array
                } else {
                    toast.error(response.error || "Failed to fetch resource categories.");
                }

                // If the user is admin or manager, fetch 'corp' categories
                if (currentUser.isAdmin() || currentUser.isManager()) {
                    // Fetch the second set of resource categories (corp)
                    const responseCorp = await getResourceCategories(1, 0, 50, 'corp');

                    // Check the success of the second response
                    if (responseCorp.success) {
                        // Attach the 'type' field to each item in the second set
                        const updatedCorpCategories = responseCorp.data.resourceCategories.map((category: any) => ({
                            ...category,
                            type: 'corp' // Assign 'corp' type to the corp set of categories
                        }));
                        allCategories = [...allCategories, ...updatedCorpCategories]; // Concatenate with second set
                    } else {
                        toast.error(responseCorp.error || "Failed to fetch corp resource categories.");
                    }
                }

                // Set the combined categories data
                setCategoriesData(allCategories);
            } catch (error) {
                toast.error("Failed to fetch resource categories.");
            }
        };

        fetchResourceCategories(); // Call the function
    }, []);


    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const resourceId = cellProps.row.original.id;
                    const type = cellProps.row.original.type;
                    const isCorp = type === 'corp'; // Check if the resource type is 'corp'
                    const isAdminOrManager =  currentUser.isAdmin(); // Check if the user is either admin or manager
                    const isCorporateUser = currentUser.isCorporate(); // Assuming there's a method to check if the user is corporate

                    return (
                        <div className="d-flex gap-3">
                            {/* Show the Edit button if the user is a corporate user OR if it's not a corp type and the user is not admin/manager */}
                            {(isCorporateUser || (isAdminOrManager && !isCorp)) && (
                                <button className="btn text-success p-0" onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleEditModal();
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" id="editTooltip"/>
                                    <UncontrolledTooltip placement="top" target="editTooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </button>
                            )}

                            {/* Always show the View button */}
                            <Link to={{
                                pathname: `/resources/${resourceId}`,
                                search: type === 'corp' ? '?resourceType=corp' : ''
                            }} className="text-primary">
                                <i className="mdi mdi-eye font-size-18" id="viewTooltip"/>
                                <UncontrolledTooltip placement="top" target="viewTooltip">
                                    View
                                </UncontrolledTooltip>
                            </Link>


                            {/* Show the Delete button if the user is a corporate user OR if it's not a corp type and the user is not admin/manager */}
                            {(isCorporateUser || (isAdminOrManager && !isCorp)) && (
                                <button className="btn text-danger p-0" onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleDeleteModal();
                                }}>
                                    <i className="mdi mdi-delete font-size-18" id="deleteTooltip"/>
                                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </button>
                            )}
                            {(!isCorporateUser  && isCorp) && (<span className={`badge-soft-info ms-1 badge bg-info rounded-pill`}>Corporate</span>)}
                        </div>
                    );
                }

            },
            // Add more columns as needed
        ],
        [toggleNewModal]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer/>
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div>
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={categories || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={!currentUser.isManager()}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search resource categories..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Category"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <ResourceCategoryModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateCategory} />
                            <ResourceCategoryModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditCategory} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this category? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteCategory}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ResourceCategoriesPage;
