import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getEventsApi as getEventsApi,
    getCategories as getCategoriesApi,
    addNewEvent as addNewEventApi,
    deleteEvent as deleteEventApi,
    updateEvent as updateEventApi,
    getGoalStepsApi,
    getGoalStepActivitiesApi,
    getClientGoalStepActivitiesApi,
    deleteScheduleEvent
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import {Get} from "type-fest";
interface GetEventsParams {
    clientId: string | number;
    client?: any;
    month: number;
    year: number;
    complete: number;
}

const getClassNameForEvent = (event, client) => {
  if (event?.schedule?.lastModifiedBy === client?.["cognitoUuid"]) {
    return "bg-info text-white"; //"#005BBB";
  } else {
    return "bg-success text-white"; // "#58A618";
  }
};

// Assuming getEventsApi returns data in the structure provided initially
export const getEvents = createAsyncThunk(
    'calendar/getEvents',
    async ({ clientId, client, month, year, complete }: GetEventsParams, { rejectWithValue }) => {
        try {
            let offset = 0;
            const limit = 50;
            let allEvents = [];
            let hasMore = true;

            // Continue fetching while there are more events
            while (hasMore) {
                const response = await getEventsApi(clientId, {
                    month,
                    year,
                    complete,
                    offset,
                    limit,
                });

                const events = response.data.events;

                // Add the fetched events to the complete list
                allEvents = allEvents.concat(events);

                // If we receive fewer events than the limit, we've reached the last page
                if (events.length < limit) {
                    hasMore = false;
                } else {
                    offset += limit; // Increment offset for the next batch
                }
            }



            // Format the events as desired
            const formattedEvents = allEvents.map((event:any) => ({
                id: event.scheduleId,
                scheduleId: event.scheduleId,
                eventId: event.eventId,
                title: event.goalStepActivity.title,
                start: event.scheduledDatetime,
                end: event.scheduledDatetime, // Assuming this is the end date. Adjust if necessary.
                className: getClassNameForEvent(event, client),
                originalEvent: event,
            }));

            return formattedEvents;
        } catch (error) {
            // Use rejectWithValue to return a rejected action with a payload
            return rejectWithValue(error || 'An error occurred while fetching events.');
        }
    }
);

export const getMultipleEvents =  createAsyncThunk("calendar/getEvents", async ({ clientId, client, month, year, complete }: GetEventsParams, { rejectWithValue }) => {
    try {
        // Calculate the next month and year
        const nextMonth = month === 12 ? 1 : month + 1;
        const nextYear = month === 12 ? year + 1 : year;

        // Fetch events for the current month
        const currentMonthResponse = await getEventsApi(clientId, { month, year, complete });
        // Fetch events for the next month
        const nextMonthResponse = await getEventsApi(clientId, { month: nextMonth, year: nextYear, complete });

        // Combine responses
        const combinedEventsData = [...currentMonthResponse.data.events, ...nextMonthResponse.data.events];

        const formattedEvents = combinedEventsData.map((event) => {
            return {
                id: event.scheduleId,
                scheduleId: event.scheduleId,
                eventId: event.eventId,
                title: event.goalStepActivity.title,
                start: event.scheduledDatetime,
                end: event.scheduledDatetime, // Assuming this is the end date. Adjust if necessary.
                className: getClassNameForEvent(event, client),
                originalEvent: event
            };
        });

        return formattedEvents;
    } catch (error) {
        return rejectWithValue(error || "An error occurred while fetching events.");
    }
});

export const getCategories = createAsyncThunk("calendar/getCategories", async () => {
    try {
        const response = await getCategoriesApi();
        return response.data.goals;
    } catch (error) {
        return error;
    }
});
export const getGoalSteps = createAsyncThunk("calendar/getGoalSteps", async (goalId: string | number, { rejectWithValue }) => {
    try {
        // Assuming `getGoalStepsApi` is a function that takes `goalId` and returns the API response
        const response = await getGoalStepsApi(goalId);

        return response.data.goalSteps; // Adjust according to your actual response structure
    } catch (error) {
        // Use `rejectWithValue` to return a custom payload or error message
        return rejectWithValue(error || "An error occurred");
    }
});
interface GoalStepActivitiesParams {
    clientId: string | number | null;
    goalId: string | number | null; // Use the correct type for goalId and goalStepId based on your data model
    goalStepId: string | number | null;
}
export const getGoalStepActivities = createAsyncThunk(
    "calendar/getGoalStepActivities",
    async ({ clientId, goalId, goalStepId }: GoalStepActivitiesParams, { rejectWithValue }) => {
        try {
            // Assuming `getGoalStepActivitiesApi` is correctly implemented to take goalId and goalStepId
            const response = await getClientGoalStepActivitiesApi( clientId,goalId, goalStepId);

            const activities = response.data.activities; // Assume this is an array

            // Create a custom activity item
            const customActivity = {
                id: 'custom', // Ensure this ID is unique and doesn't clash with real IDs
                title: 'Custom Activity', // The display text you want for the custom item
                // Add any other necessary properties for the custom item here
            };

            // Insert the custom item into the activities array
            // You can adjust where to insert based on your needs (e.g., at the beginning or end)
            const modifiedActivities = [customActivity, ...activities];

            return modifiedActivities;


        } catch (error) {
            return rejectWithValue(error  || "An error occurred");
        }
    }
);
interface AddNewEventParams {
    clientId: string | number | null; // Assuming clientId is always a string, adjust as necessary
    event: any; // Replace 'any' with a more specific type or interface for your event object
}

export const addNewEvent = createAsyncThunk("calendar/addNewEvent", async ({ clientId, event } : AddNewEventParams, { rejectWithValue }) => {
    try {
        // Ensure your addNewEventApi can accept clientId and adjust the endpoint accordingly
        const response = await addNewEventApi(clientId, event);

        toast.success("Event Added Successfully", { autoClose: 2000 });
        return response.data;
    } catch (error) {
        toast.error("Event Add Failed", { autoClose: 2000 });
        // Use rejectWithValue to return a custom payload for the rejected action
        // @ts-ignore
        return rejectWithValue(error.response?.data || "Unknown error");
    }
});
interface DeleteAllEventParams {
    clientId: string | number | null;
    scheduleId: string | number | null; // Adjust based on your data model
}
interface DeleteEventParams {
    clientId: string | number | null;
    scheduleId: string | number | null; // Adjust based on your data model
    eventId: string | number | null;
}
export const deleteAllEvents = createAsyncThunk(
    "calendar/deleteAllEvents",
    async ({ clientId, scheduleId }: DeleteAllEventParams, { rejectWithValue }) => {
        try {
            // Ensure your deleteEventApi can accept clientId and scheduleId to construct the endpoint accordingly
            const response = await deleteEventApi(clientId, scheduleId);
            toast.success("Events Deleted Successfully", { autoClose: 2000 });
            return response.data;
        } catch (error) {
            toast.error("Event Delete Failed", { autoClose: 2000 });
            // Use rejectWithValue to return a custom payload for the rejected action
            // @ts-ignore
            return rejectWithValue(error.response?.data || "Unknown error");
        }
    }
);
export const deleteEvent = createAsyncThunk(
    "calendar/deleteEvent",
    async ({ clientId, scheduleId, eventId }: DeleteEventParams, { rejectWithValue }) => {
        try {
            // Ensure your deleteEventApi can accept clientId and scheduleId to construct the endpoint accordingly
            const response = await deleteScheduleEvent(clientId, scheduleId, eventId);
            toast.success("Event Deleted Successfully", { autoClose: 2000 });
            return response.data;
        } catch (error) {
            toast.error("Event Delete Failed", { autoClose: 2000 });
            // Use rejectWithValue to return a custom payload for the rejected action
            // @ts-ignore
            return rejectWithValue(error.response?.data || "Unknown error");
        }
    }
);
interface UpdateEventParams {
    clientId: string | number | null;
    scheduleId: string | number;
    eventId: string | number;
    event: any; // Ideally, replace 'any' with a more specific type that represents your event object
}

export const updateEvent = createAsyncThunk(
    "calendar/updateEvent",
    async ({ clientId, scheduleId, eventId, event }: UpdateEventParams, { rejectWithValue }) => {
        try {
            // Ensure your updateEventApi can accept clientId, scheduleId, and event to construct the request accordingly
            const response = await updateEventApi(clientId, scheduleId,eventId, event);
            toast.success("Event Updated Successfully", { autoClose: 2000 });
            return response.data;
        } catch (error) {
            toast.error("Event Update Failed", { autoClose: 2000 });
            // Use rejectWithValue to return a custom payload for the rejected action
            // @ts-ignore
            return rejectWithValue(error.response?.data || "Unknown error");
        }
    }
);
